<template>
  <el-dialog
    :model-value="true"
    class="cancel-success-modal"
    align-center
    :width="modalWidth"
    :show-close="false"
    :close-on-click-modal="false"
  >
    <div class="header-container">
      <icon-button
        name="icon_close"
        :size="20"
        @click="$emit('close')"
      />
    </div>
    <div class="content-container">
      <Question @submit="submit"/>
    </div>
  </el-dialog>
</template>

<script setup>
import Question from "./question.vue";
const props = defineProps({
  modelValue: {
    type: Boolean,
  }
});
const emits = defineEmits(["close", "submit"]);

const { VITE_CANCEL_URL } = import.meta.env;
const modalWidth = ref(1060);

const computedModalWidth = () => {
  const screenWidth = window.innerWidth;
  if (screenWidth > 4000) {
    modalWidth.value = 2500;
  }else if (screenWidth > 3000 && screenWidth < 4000) {
    modalWidth.value = 2000;
  } else if (screenWidth > 2400 && screenWidth < 3000) {
    modalWidth.value = 1500;
  } else {
    modalWidth.value = 1060;
  }
};

const submit = (e) => {
  emits("submit");
};

onMounted(() => {
  computedModalWidth();
  window.onresize = () => {
    computedModalWidth();
  };
});
</script>
<style lang="scss" scoped>
.cancel-success-modal {
  &.el-dialog {
    width: 65%;
    height: 80%;
    min-width: 1070px;
    min-height: 500px;
  }
  
  & > .el-dialog__header {
    padding: 0;
    margin: 0;
  }

  & > .el-dialog__body {
    height: 100%;
    padding: 0px 0px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  & > .el-dialog__footer {
    padding: 20px;
  }
}
</style>
<style lang="scss" scoped>
.header-container {
  display: flex;
  justify-content: flex-end;
  padding: 22px 28px;
  margin: 0;
  border-bottom: 1px solid #e5e7eb;
}

.content-container {
  height: calc(100% - 72px);
}
</style>