import i18n from "@/i18n";

const { t } = i18n.global;
// Card Init copywriting
export const starterPriorityList = [
  t('bvPricing.starterPriorityList.item1'),
  t('bvPricing.starterPriorityList.item2'),
  t('bvPricing.starterPriorityList.item3'),
];

export const starterPlanDescription = t('bvPricing.starterPlanDescription');

export const starterPriorityDescription = t('bvPricing.starterPriorityDescription');

export const standardPriorityList = [
  "60 Video exports / month",
  "600 Voiceover transcription mins",
  "Unlimited AI image tools",
  "30min AI tools credits / month",
  "500 AI-generated credits",
  "100+ Standard templates",
  "3 Brand kits",
  "10G Cloud storage",
  "Al image recognition",
  "GPT-4 powered script-writing",
];

export const yearlyStandardPlanDescription = t('bvPricing.yearlyStandardPlanDescription');

export const standardPlanDescription = t('bvPricing.standardPlanDescription');

export const standardPriorityDescription = t('bvPricing.standardPriorityDescription');

export const proPriorityList = [
  "Unlimited video exports / month",
  "1500 Voiceover transcription mins",
  "20 Picture to dynamic video credits",
  "Unlimited AI image tools",
  "90min AI tools credits / month",
  "5000 AI-generated credits",
  "500+ Premium templates",
  "Unlimited brand kits",
  "100G Cloud storage",
  "Al image recognition",
  "GPT-4 powered script-writing",
];

export const proPlanDescription = t('bvPricing.proPlanDescription');

export const proPriorityDescription = t('bvPricing.proPriorityDescription');

export const ultraPriorityList = [
  t('bvPricing.ultraPriorityList.item1'),
  t('bvPricing.ultraPriorityList.item2'),
  t('bvPricing.ultraPriorityList.item3'),
  t('bvPricing.ultraPriorityList.item4'),
  t('bvPricing.ultraPriorityList.item5'),
  "More Picture to dynamic video credits",
];

export const ultraPlanDescription = t('bvPricing.ultraPlanDescription');

export const ultraPriorityDescription = t('bvPricing.ultraPriorityDescription');

export const faqList = [
  {
    title: t('bvPricing.faqList.question1.title'),
    content: t('bvPricing.faqList.question1.content'),
  },
  // {
  //   title: t('bvPricing.faqList.question2.title'),
  //   content: t('bvPricing.faqList.question2.content'),
  // },
  {
    title: t('bvPricing.faqList.question3.title'),
    content: t('bvPricing.faqList.question3.content'),
  },
  {
    title: t('bvPricing.faqList.question4.title'),
    content: t('bvPricing.faqList.question4.content'),
  },
  {
    title: t('bvPricing.faqList.question5.title'),
    content: t('bvPricing.faqList.question5.content'),
  },
];

export const cancelTips = {
  300401: [
    t('bvPricing.cancelTips.300401.item1'),
    t('bvPricing.cancelTips.300401.item2'),
    t('bvPricing.cancelTips.300401.item3'),
    t('bvPricing.cancelTips.300401.item4'),
    t('bvPricing.cancelTips.300401.item5'),
    t('bvPricing.cancelTips.300401.item6'),
  ],
  300201: [
    t('bvPricing.cancelTips.300201.item1'),
    t('bvPricing.cancelTips.300201.item2'),
    t('bvPricing.cancelTips.300201.item3'),
    t('bvPricing.cancelTips.300201.item4'),
    t('bvPricing.cancelTips.300201.item5'),
    t('bvPricing.cancelTips.300201.item6'),
  ],
  300301: [
    t('bvPricing.cancelTips.300301.item1'),
    t('bvPricing.cancelTips.300301.item2'),
    t('bvPricing.cancelTips.300301.item3'),
    t('bvPricing.cancelTips.300301.item4'),
    t('bvPricing.cancelTips.300301.item5'),
    t('bvPricing.cancelTips.300301.item6'),
    t('bvPricing.cancelTips.300301.item7'),
  ],
  300402: [
    t('bvPricing.cancelTips.300402.item1'),
    t('bvPricing.cancelTips.300402.item2'),
    t('bvPricing.cancelTips.300402.item3'),
    t('bvPricing.cancelTips.300402.item4'),
    t('bvPricing.cancelTips.300402.item5'),
    t('bvPricing.cancelTips.300402.item6'),
  ],
  300202: [
    t('bvPricing.cancelTips.300202.item1'),
    t('bvPricing.cancelTips.300202.item2'),
    t('bvPricing.cancelTips.300202.item3'),
    t('bvPricing.cancelTips.300202.item4'),
    t('bvPricing.cancelTips.300202.item5'),
    t('bvPricing.cancelTips.300202.item6'),
  ],
  300302: [
    t('bvPricing.cancelTips.300302.item1'),
    t('bvPricing.cancelTips.300302.item2'),
    t('bvPricing.cancelTips.300302.item3'),
    t('bvPricing.cancelTips.300302.item4'),
    t('bvPricing.cancelTips.300302.item5'),
    t('bvPricing.cancelTips.300302.item6'),
    t('bvPricing.cancelTips.300302.item7'),
  ],
};  