import { openPremiumInTab } from "@/utils/premium";
import { openVideoEditor } from "@/router/open";
import facebookIcon from "@/assets/icons/home/icon_facebook.svg";
import instagramIcon from "@/assets/icons/home/icon_instagram.svg";
import pinterestIcon from "@/assets/icons/home/icon_pinterest.svg";
import linkedinIcon from "@/assets/icons/home/icon_linkedin.svg";
import twitterIcon from "@/assets/icons/home/icon_twitter.svg";
import youtubeIcon from "@/assets/icons/home/icon_youtube.svg";
import mediumIcon from "@/assets/icons/home/icon_medium.svg";
import type { Router } from "vue-router";
import i18n from "@/i18n";

const { t } = i18n.global;
export type MenuItem =
  | {
      name: string;
      path: string;
      link?: boolean;
      query?: {
        [key: string]: string;
      };
    }
  | {
      name: string;
      handler: (router: Router) => void;
    };

export interface Menu {
  key: string;
  name: string;
  items: MenuItem[];
}

export type GroupMenuItem = MenuItem & {group?: string};

export type HeaderMenu = {
  key: string;
  name: string;
  items: GroupMenuItem[];
} | MenuItem;

export const headerMenuList: HeaderMenu[] = [
  {
    key: "Features",
    name: t("home.header.features"),
    items: [
      {
        name: t("home.header.videoTools.productUrlToVideo"),
        path: "/feature/product-to-video",
        link: true,
        group: "Video tools",
      },
      {
        name: t("home.header.videoTools.ideaToVideo"),
        path: "/feature/idea-to-video",
        link: true,
        group: "Video tools",
      },
      {
        name: t("home.header.videoTools.scriptToVideo"),
        path: "/feature/script-to-video",
        link: true,
        group: "Video tools",
      },
      {
        name: t("home.header.videoTools.blogToVideo"),
        path: "/feature/blog-to-video",
        link: true,
        group: "Video tools",
      },
      {
        name: t("home.header.videoTools.visualsToVideo"),
        path: "/feature/visuals-to-video",
        link: true,
        group: "Video tools",
      },
      {
        name: t("home.header.videoTools.templatesToVideo"),
        path: "/feature/template-to-video",
        link: true,
        group: "Video tools",
      },
      {
        name: t("home.header.videoTools.startFromScratch"),
        path: "/feature/web-editor",
        link: true,
        group: "Video tools",
      },
      {
        name: t("home.header.aiTools.imageEnhancer"),
        path: "/feature/ai-tools",
        link: true,
        group: "AI tools",
      },
      {
        name: t("home.header.aiTools.imageBackgroundRemover"),
        path: "/feature/ai-tools",
        link: true,
        group: "AI tools",
      },
      {
        name: t("home.header.aiTools.objectEraser"),
        path: "/feature/ai-tools",
        link: true,
        group: "AI tools",
      },
      {
        name: t("home.header.aiTools.videoBackgroundRemover"),
        path: "/feature/ai-tools",
        link: true,
        group: "AI tools",
      },
      {
        name: t("home.header.aiTools.videoEnhancer"),
        path: "/feature/ai-tools",
        link: true,
        group: "AI tools",
      },
    ],
  },
  {
    key: "Use Cases",
    name: t("home.header.useCases"),
    items: [
      {
        name: t("home.header.useCasesList.ecommerceSeller"),
        link: true,
        path: "/use-case/ecommerce-seller",
      },
      {
        name: t("home.header.useCasesList.contentCreator"),
        link: true,
        path: "/use-case/content-creator",
      },
      {
        name: t("home.header.useCasesList.socialMediaMarketer"),
        link: true,
        path: "/use-case/social-media-marketer",
      },
      {
        name: t("home.header.useCasesList.photographer"),
        link: true,
        path: "/use-case/photographer",
      },
      {
        name: t("home.header.useCasesList.artist"),
        link: true,
        path: "/use-case/artist",
      },
    ],
  },
  {
    key: "Resources",
    name: t("home.header.resources"),
    items: [
      {
        name: t("home.header.resourcesList.blog"),
        path: "https://boolv.video/blog/",
      },
      {
        name: t("home.header.resourcesList.newsletter"),
        path: "https://socialmediamaven.substack.com/",
      },
      {
        name: t("home.header.resourcesList.changelog"),
        path: "https://boolv.notion.site/Changelog-62ad113ea2fc42718002ecf225f88d44?pvs=74",
      },
      {
        name: t("home.header.resourcesList.roadmap"),
        path: "https://roadmap.boolv.video/roadmap",
      },
      {
        name: t("home.header.resourcesList.featureRequest"),
        path: "https://roadmap.boolv.video",
      },
      {
        name: t("home.header.resourcesList.joinDiscord"),
        path: "https://discord.com/invite/WSC2FyGYCn",
      },
      {
        name: t("home.header.resourcesList.affiliatePartnerProgram"),
        path: "/affiliate-program",
      }
    ],
  },
  {
    key: "Pricing",
    name: t("home.header.pricing"),
    link: true,
    path: "/premium",
  },
];

export const footerMenuList: Menu[] = [
  {
    key: "Features",
    name: t('home.footer.features'),
    items: [
      {
        name: t('home.footer.productUrlToVideo'),
        path: "/product-to-video",
        link: true,
      },
      {
        name: t('home.footer.ideaToVideo'),
        path: "/idea-to-video",
        link: true,
      },
      {
        name: t('home.footer.scriptToVideo'),
        path: "/script-to-video",
        link: true,
      },
      {
        name: t('home.footer.blogToVideo'),
        path: "/blog-to-video",
        link: true,
      },
      {
        name: t('home.footer.visualsToVideo'),
        path: "/visuals-to-video",
        link: true,
      },
      {
        name: t('home.footer.templatesToVideo'),
        path: "/template-video-list",
        link: true,
      },
      {
        name: t('home.footer.startFromScratch'),
        path: "/editor",
        link: true,
      },
      {
        name: t('home.footer.imageEnhancer'),
        path: "/aiTools/upload",
        link: true,
        query: {
          aiToolType: 'imageEnhancer',
        }
      },
      {
        name: t('home.footer.imageBackgroundRemover'),
        path: "/aiTools/upload",
        link: true,
        query: {
          aiToolType: 'backgroundRemover',
        }
      },
      {
        name: t('home.footer.objectEraser'),
        path: "/aiTools/upload",
        link: true,
        query: {
          aiToolType: 'objectErase',
        }
      },
      {
        name: t('home.footer.videoBackgroundRemover'),
        path: "/aiTools/upload",
        link: true,
        query: {
          aiToolType: 'videoBackgroundRemover',
        }
      },
      {
        name: t('home.footer.videoEnhancer'),
        path: "/aiTools",
        link: true,
      },
    ],
  },
  {
    key: "Resources",
    name: t('home.footer.resources'),
    items: [
      {
        name: t('home.footer.blog'),
        path: "https://boolv.video/blog/",
      },
      {
        name: t('home.footer.newsletter'),
        path: "https://socialmediamaven.substack.com/",
      },
      {
        name: t('home.footer.changelog'),
        path: "https://boolv.notion.site/Changelog-62ad113ea2fc42718002ecf225f88d44?pvs=74",
      },
      {
        name: t('home.footer.roadmap'),
        path: "https://roadmap.boolv.video/roadmap",
      },
      {
        name: t('home.footer.featureRequest'),
        path: "https://roadmap.boolv.video",
      },
      {
        name: t('home.footer.joinDiscord'),
        path: "https://discord.com/invite/WSC2FyGYCn",
      },
      {
        name: t('home.footer.affiliatePartnerProgram'),
        path: "/affiliate-program",
        link: true,
      },
      {
        name: t('home.footer.templates'),
        path: "/template-gallery",
        link: true,
      },
    ],
  },
  {
    key: "Company",
    name: t('home.footer.company'),
    items: [
      {
        name: t('home.footer.contact'),
        path: "mailto:support@boolvector.com",
      },
      {
        name: t('home.footer.aboutUs'),
        path: "/about",
        link: true,
      },
      {
        name: t('home.footer.partner'),
        path: "https://www.quickcep.com/",
      },
      {
        name: t('home.footer.termsOfService'),
        path: "/terms",
        link: true,
      },
      {
        name: t('home.footer.privacyPolicy'),
        path: "/privacy-policy",
        link: true,
      },
      {
        name: t('home.footer.cookiesPolicy'),
        path: "/cookies-policy",
        link: true,
      },
    ],
  },
];

export const socialMediaList = [
  {
    key: "twitter",
    name: "twitter",
    link: "https://twitter.com/boolvtech",
    icon: twitterIcon,
  },
  {
    key: "youtube",
    name: "youtube",
    link: "https://www.youtube.com/channel/UCkzHe1HYa5Ijcbci_WeBjYA",
    icon: youtubeIcon,
  },
  {
    key: "pinterest",
    name: "pinterest",
    link: "https://www.pinterest.com/boolv_tech/",
    icon: pinterestIcon,
  },
  {
    key: "instagram",
    name: "instagram",
    link: "https://www.instagram.com/stylemastersss/",
    icon: instagramIcon,
  },
  {
    key: "medium",
    name: "medium",
    link: "https://medium.com/@boolv",
    icon: mediumIcon,
  },
  {
    key: "linkedin",
    name: "linkedin",
    link: "https://www.linkedin.com/company/boolv-tech",
    icon: linkedinIcon,
  },
  {
    key: "facebook",
    name: "facebook",
    link: "https://www.facebook.com/Boolv-Tech-106608845463051",
    icon: facebookIcon,
  },
] as {
  key: string;
  name: string;
  link: string;
  icon: string;
}[];
