<template>
  <el-dialog
    modal-class="edit-export-info-modal"
    v-model="dialogVisible"
    destroy-on-close
    :close-on-click-modal="false"
    append-to-body
    :show-close="false"
    :align-center="true"
    width="415px"
  >
    <div class="pt-6 px-6 relative">
      <div class="mb-6">
        <div class="text-black font-medium text-base">Export</div>
      </div>
      <div
        class="absolute top-6 right-6 h-6 w-6 hover:bg-uploadBg flex items-center justify-center rounded-sm cursor-pointer"
        @click="handleClose"
      >
        <svg-icon
          name="icon_export_close"
          :style="{ width: '24px', height: '24px' }"
          color="#646A73"
        ></svg-icon>
      </div>

      <el-form :model="form" label-width="70px" label-position="left">
        <el-form-item>
          <template #label>
            <p class="text-titleColor">Name</p>
          </template>
          <el-input v-model="form.name" />
        </el-form-item>
        <el-form-item>
          <template #label>
            <p class="text-titleColor">Resolution</p>
          </template>
          <el-select
            v-model="form.resolution"
            class="w-full"
            popper-class="select-container"
            :popper-append-to-body="false"
            :suffix-icon="SelectSuffix"
          >
            <el-option
              :value="item.value"
              v-for="item in resolutionList"
              :key="item.value"
            >
              <div class="h-full flex items-center justify-between">
                <p class="text-sm">{{ item.label }}</p>
                <svg-icon
                  name="icon_right"
                  :style="{ width: '16px', height: '16px', color: '#875EFF' }"
                  v-if="item.value == form.resolution"
                ></svg-icon>
              </div>
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>

    <template #footer>
      <div class="flex items-center justify-end pr-6">
        <primary-button
          :showDefaultIcon="false"
          size="extra-small"
          @click="handleExport"
        >
          <p class="text-sm font-normal flex items-center leading-22">
            <svg-icon
              name="icon_loading"
              :style="{ width: '16px', height: '16px' }"
              class="animate-spin duration-300 mr-2"
              v-if="loading"
            ></svg-icon>
            Export
          </p>
        </primary-button>
      </div>
    </template>

    <SubscribeDialog
      :visible="subscribeModalVisible"
      @close="subscribeModalVisible = false"
      :showIntroduction="false"
    />
  </el-dialog>
</template>
<script setup lang="ts">
import { PrimaryButton } from '@/components/common/index';
import { ref, watch, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';

import { exportVideo, exportEditVideo } from '@/api/index';
import { useSingleMessage } from '@/utils';

import { useEditVideo } from '@/store/modules/editVideo';
import { useModalManager } from '@/components/common/custom-modal/instance';
import SelectSuffix from '@/components/common/selectSuffix.vue';
import SubscribeDialog from '@/layout/components/workspace/subscribeDialog/index.vue';

import { useTrackStore } from '@/store/modules/track';

const { track, collectData } = useTrackStore();
const modalManager = useModalManager();
const Message = useSingleMessage();
const emit = defineEmits(['close', 'confirm']);

const props = defineProps({
  visible: Boolean,
  exportType: String,
  rid: {
    type: String,
    default: '',
  },
  reqId: {
    type: String,
    default: '',
  },
  draftId: {
    type: String,
    default: '',
  },
  editAssets: Object,
});

const form = reactive({
  name: 'Untitled',
  resolution: '480P',
});

const editVideoStore = useEditVideo();
const { isSaving } = storeToRefs(editVideoStore);

const subscribeModalVisible = ref(false);
const timer: any = ref(null);
const router = useRouter();
const draftId = ref<string | undefined>('');
const loading = ref(false);
const resolutionList = ref([
  {
    value: '1080P',
    label: '1080P',
  },
  {
    value: '720P',
    label: '720P',
  },
  {
    value: '480P',
    label: '480P',
  },
]);

watch(
  () => props.visible,
  (value) => {
    dialogVisible.value = value;
  }
);

const dialogVisible = ref(props.visible);

/**
 * 替换
 */
const exportFn = async () => {
  loading.value = true;
  // 清除定时器
  clearInterval(timer.value);
  timer.value = null;
  try {
    if (props.exportType === 'previewer') {
      const params: CreateVideoReqParams.ExportVideoParams = {
        name: form.name,
        rid: props.rid,
        resolution:
          form.resolution == '1080P'
            ? 1080
            : form.resolution == '720P'
            ? 720
            : 480,
        reqId: props.reqId,
      };

      const { data, code, msg, noCredit } = await exportVideo(params);

      if (noCredit) {
        loading.value = false;
        modalManager.applyTemplate('upgradeTips', {
          msg,
          code,
          onConfirm: () => {
            subscribeModalVisible.value = true;
          },
        });
        emit('close');
        return;
      }

      draftId.value = data;
    }

    if (props.exportType === 'edit') {
      if (!props.draftId || !form.resolution) {
        loading.value = false;
        return;
      }

      const params: CreateVideoReqParams.EditExportVideoParams = {
        name: form.name,
        draftId: props.draftId,
        resolution:
          form.resolution == '1080P'
            ? 1080
            : form.resolution == '720P'
            ? 720
            : 480,
        reqId: props.reqId,
      };

      const { data, msg, code, noCredit } = await exportEditVideo(params);

      if (noCredit) {
        loading.value = false;
        modalManager.applyTemplate('upgradeTips', {
          msg,
          code,
          onConfirm: () => {
            subscribeModalVisible.value = true;
          },
        });
        emit('close');
        return;
      }
      draftId.value = data;
    }
    track('boolvideo_export_click');
    loading.value = false;
    dialogVisible.value = false;
    emit('close');
    emit('confirm', draftId);

    const url = router.resolve({
      path: '/space',
      query: {
        tab: 'exported',
      },
    });
    // 打开新窗口
    window.open(url.href);
  } catch (err) {
    loading.value = false;
    Message.error('Can’t export, please try again', { duration: 3000 });
  }
};

const handleExport = async () => {
  if (isSaving.value) {
    // 正在保存，不允许导出
    timer.value = setInterval(() => {
      handleExport();
    }, 1000);
    return;
  }
  exportFn();
};

// 删除
const handleClose = () => {
  dialogVisible.value = false;
  emit('close');
};
</script>
<style lang="scss">
.edit-export-info-modal {
  :deep(.el-dialog) {
    border-radius: 4 !important;
  }

  .el-dialog__header {
    padding: 0 !important;
  }

  .el-dialog__body {
    padding: 0px;
  }

  .el-dialog__footer {
    padding: 0px 0 24px 0;
  }

  .el-progress-bar__outer {
    height: 4px;
  }

  .el-input {
    width: 279px;
  }

  .el-input__wrapper {
    padding: 4px 12px 4px 12px;

    .el-input__suffix,
    .el-input__suffix-inner,
    .el-icon {
      width: 18px;
      height: 18px;
    }
  }

  .el-input__inner {
    height: 32px;
    text-align: left;
    padding: 0;
    font-weight: 400;
    font-size: 14px;
  }

  .el-form-item__label {
    height: 40px;
    margin-right: 18px;

    p {
      height: 40px;
      line-height: 40px;
    }
  }

  .el-form-item {
    margin-bottom: 24px;
  }
}

:deep(.el-overlay.asset-modal) {
  background-color: rgba(0, 0, 0, 0.54);
}
</style>

<style lang="scss">
.el-select-dropdown__item {
  padding: 5px 12px;
  line-height: 22px;
  font-size: 14px;
  color: #060606;
}

.el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background: rgba(31, 35, 41, 0.08);
}

.el-select-dropdown__item.selected {
  font-weight: 400;
}

.select-container .el-popper__arrow {
  display: none;
}

.el-select-dropdown__list {
  padding: 8px 0;
}
</style>
