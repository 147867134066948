import { ref, getCurrentInstance, onMounted, onBeforeUnmount } from "vue";
import { createDefaultValueProxy, createDynamicRef } from "@/utils/type";
import type { ConcreteComponent, Ref } from "vue";

export function useScopeId() {
  const instance = getCurrentInstance();

  return (instance!.type as ConcreteComponent & { __scopeId: string })[
    "__scopeId"
  ];
}
export interface MoveEvent {
  x: number;
  y: number;
  deltaX: number;
  deltaY: number;
  target: EventTarget;
  self: Element;
  state: "start" | "move" | "end";
  originalEvent: PointerEvent;
}

export class MoveEvent {
  constructor(e: PointerEvent, self: Element) {
    this.x = 0;
    this.y = 0;
    this.deltaX = 0;
    this.deltaY = 0;
    this.target = e.target || self;
    this.self = self;
    this.originalEvent = e;
    this.state = "move";
  }

  abort() {}
}

export interface PointerMoveConfig {
  suppressX?: boolean;
  suppressY?: boolean;
  overflowX?: boolean;
  overflowY?: boolean;
  capturePointer?: boolean;
  handler: (e: MoveEvent) => void;
}

export function usePointerMove(config: PointerMoveConfig) {
  let offsetX = 0;
  let offsetY = 0;
  let _elRef: Ref<Element> = ref(null as unknown as Element);

  const _config = createDefaultValueProxy(config, {
    suppressX: false,
    suppressY: false,
    overflowX: true,
    overflowY: true,
    capturePointer: true,
  });

  const bindTarget = _config.capturePointer ? createDynamicRef(() => _elRef.value) : window;

  function handleEvent(e: PointerEvent, state: "start" | "move" | "end") {
    const boundingRect = _elRef.value.getBoundingClientRect();
    const event = new MoveEvent(e, _elRef.value);

    event.state = state;
    event.abort = abortEvent;

    if (!_config.suppressX) {
      event.x = e.clientX - boundingRect.left;
      event.deltaX = state === "move" ? e.clientX - offsetX : 0;

      if (!_config.overflowX) {
        const now = event.x;
        const pre = event.x - event.deltaX;
        event.x = Math.min(boundingRect.width, Math.max(0, event.x));

        if (
          (pre < 0 && now < 0) ||
          (pre > boundingRect.width && now > boundingRect.width)
        ) {
          event.deltaX = 0;
        } else if (event.x === 0) {
          event.deltaX = Math.sign(now - pre) * Math.max(now, pre);
        } else if (event.x === boundingRect.width) {
          event.deltaX =
            Math.sign(now - pre) * (boundingRect.width - Math.min(now, pre));
        }
      }
    }

    if (!_config.suppressY) {
      event.y = e.clientY - boundingRect.top;
      event.deltaY = state === "move" ? e.clientY - offsetY : 0;

      if (!_config.overflowY) {
        const now = event.y;
        const pre = event.y - event.deltaY;
        event.y = Math.min(boundingRect.height, Math.max(0, event.y));

        if (
          (pre < 0 && now < 0) ||
          (pre > boundingRect.height && now > boundingRect.height)
        ) {
          event.deltaY = 0;
        } else if (event.y === 0) {
          event.deltaY = Math.sign(now - pre) * Math.max(now, pre);
        } else if (event.y === boundingRect.height) {
          event.deltaY =
            Math.sign(now - pre) * (boundingRect.height - Math.min(now, pre));
        }
      }
    }

    return event;
  }

  function handlePointerDown(e: Event) {
    if (!(e instanceof PointerEvent) || e.button !== 0) return;
    offsetX = e.clientX;
    offsetY = e.clientY;

    const event = handleEvent(e, "start");

    bindTarget.addEventListener("pointermove", handlePointerMove);
    bindTarget.addEventListener("pointerup", handlePointerUp, { once: true });
    bindTarget.addEventListener("pointercancel", handlePointerUp, { once: true });

    if (_config.capturePointer) {
      _elRef.value.setPointerCapture(e.pointerId);
    }

    _config.handler(event);
  }

  function handlePointerMove(e: Event) {
    if (!(e instanceof PointerEvent)) return;

    const event = handleEvent(e, "move");
    offsetX = e.clientX;
    offsetY = e.clientY;

    if (event.deltaX !== 0 || event.deltaY !== 0) {
      _config.handler(event);
    }
  }

  function handlePointerUp(e: Event) {
    if (!(e instanceof PointerEvent)) return;
    const event = handleEvent(e, "end");
    
    event.abort();
    _config.handler(event);
  }

  function abortEvent(this: MoveEvent) {
    bindTarget.removeEventListener("pointermove", handlePointerMove);
    bindTarget.removeEventListener("pointerup", handlePointerUp);
    bindTarget.removeEventListener("pointercancel", handlePointerUp);

    if (config.capturePointer) {
      this.self.releasePointerCapture(this.originalEvent.pointerId);
    }
  }

  watch(_elRef, (value, oldValue) => {
    if (value === null) {
      oldValue.removeEventListener("pointerdown", handlePointerDown);
    } else {
      value.addEventListener("pointerdown", handlePointerDown);
    }
  });

  return {
    ref: _elRef as Ref<Element | null>,
  };
}


// @ts-ignore
export function usePageLoader(queryFunc, initialParams = {}, options = {}) {
  const list = ref([]);
  const loading = ref(false);
  const page = ref(1);
  const hasMore = ref(true);
  const metaData = ref({});
  
  const params = computed(() => ({
    ...initialParams,
    page: page.value,
    ...metaData.value,
  }));

  const loadMore = async () => {
    if (loading.value || !hasMore.value) return [];
    
    loading.value = true;
    try {
      const { data, success } = await queryFunc(params.value);
      if (success) {
        if(options.customFormat){
          data.records = options.customFormat(data.records);
        }
        list.value.push(...data.records);
        page.value++;
      }
      hasMore.value = data.current * data.size < data.total;
    } catch (error) {
      console.error('Error loading data:', error);
    } finally {
      loading.value = false;
    }
  };

  const search = (query) => {
    metaData.value = query;
    reset();
    loadMore();
  };

  const reset = () => {
    list.value = [];
    page.value = 1;
    hasMore.value = true;
  };


  return {
    list,
    loading,
    hasMore,
    loadMore,
    search,
  };
}