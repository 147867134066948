import en from './message/en.json';
import es from './message/es.json';
import { createI18n } from "vue-i18n";
import { setItem, getItem } from "@/utils/storage";

const messages = {
  en,
  es,
};

const  convertRFC5646ToISO639 = (rfc5646) => {
  if (!rfc5646) return "";
  const parts = rfc5646.split('-');
  return parts[0]; // 返回ISO 639语言代码部分
};

const initLocale = () => {
  const navLang = convertRFC5646ToISO639(navigator.language || navigator.userLnaguage);
  const convertedLang = convertRFC5646ToISO639(navLang);

  const debugLang = new URLSearchParams(window.location.search).get('lang');
  if(debugLang){
    return debugLang;
  }
  const locale = convertedLang || getItem("locale") || "en";
  setItem("locale", locale);
  return locale;
};

const defaultLocale = initLocale();
const i18n = createI18n({
  legacy: false,
  locale: defaultLocale,
  fallbackLocale: ['en', 'es'],
  messages,
});

export default i18n;

export const setLocale = (locale) => {
  i18n.global.locale = locale;
  setItem("locale", locale);
};

