import { useCreatorStore } from "./creator";

const { activeNodeMap, currentFrame, addNode, removeActiveNodes } =
  useCreatorStore();
const copyNodes = reactive(new Map());
const copyedKeyframe = ref(null);
const canCopy = computed(() => activeNodeMap.size > 0);
const canCut = computed(() => activeNodeMap.size > 0);
const canPaste = computed(() => copyNodes.size > 0);
const canPasteKeyframeNode = computed(() => {
  if (activeNodeMap.size === 1) {
    for (const node of activeNodeMap.values()) {
      if (
        ["image", "video", "sticker", "text", "graphic", "effect"].includes(
          node.type
        ) &&
        node.startFrame <= currentFrame.value &&
        currentFrame.value <= node.endFrame
      ) {
        return node;
      }
      return null;
    }
  }
  return null;
});
const canPasteKeyframe = computed(
  () => Boolean(copyedKeyframe.value) && Boolean(canPasteKeyframeNode.value)
);

function setCopyNodes() {
  copyNodes.clear();
  for (const node of activeNodeMap.values()) {
    const duration = node.getDuration();
    const zIndex = node.getZIndex();
    copyNodes.set(node.id, { ...node.conf, duration, zIndex });
  }
  copyedKeyframe.value = null;
}

function setCopyedKeyframe(keyframe) {
  copyedKeyframe.value = keyframe;
  copyNodes.clear();
}

function copy() {
  if (canCopy.value) {
    setCopyNodes();
  }
}

function cut() {
  setCopyNodes();
  removeActiveNodes();
}

function paste() {
  if (copyNodes.size > 0) {
    activeNodeMap.clear();
  }
  for (const config of copyNodes.values()) {
    const delta = currentFrame.value - config.start;
    const { animation, keyframes, mask } = config;

    if (animation) {
      const newAnimation = {};
      const animIn = animation.in;
      const animOut = animation.out;

      if (animIn) {
        const anim = { ...animIn };
        anim.start = anim.start + delta;
        anim.end = anim.end + delta;
        newAnimation.in = anim;
      }
      if (animOut) {
        const anim = { ...animOut };
        anim.start = anim.start + delta;
        anim.end = anim.end + delta;
        newAnimation.out = anim;
      }
      config.animation = newAnimation;
    }
    if (keyframes) {
      const newKeyframes = {};
      for (const frameString of Object.keys(keyframes)) {
        const frame = (parseInt(frameString) + delta).toString();
        newKeyframes[frame] = keyframes[frameString];
      }
      config.keyframes = newKeyframes;
    }
    if (mask && mask.keyframes) {
      const newKeyframes = {};
      for (const frameString of Object.keys(mask.keyframes)) {
        const frame = (parseInt(frameString) + delta).toString();
        newKeyframes[frame] = mask.keyframes[frameString];
      }
      config.mask = { ...mask, keyframes: newKeyframes };
    }
    addNode(config);
  }
}

function pasteKeyframe() {
  const node = canPasteKeyframeNode.value;
  if (!node.conf.keyframes) {
    node.conf.keyframes = {};
  }
  node.conf.keyframes[currentFrame.value] = copyedKeyframe.value;
}

function duplicate() {
  copy();
  paste();
}

export function useCopyStore() {
  return {
    canCut,
    canCopy,
    canPaste,
    canPasteKeyframe,
    cut,
    copy,
    paste,
    duplicate,
    setCopyedKeyframe,
    pasteKeyframe,
  };
}
