<template>
  <el-dialog
    :model-value="modelValue"
    class="cancel-success-modal"
    align-center
    :width="modalWidth"
    :show-close="false"
    :close-on-click-modal="false"
    @close="close"
  >
    <div class="header-container">
      <icon-button
        name="icon_close"
        :size="20"
        @click="close"
      />
    </div>
    <div class="content-container">
      <el-scrollbar>
        <div class="content-box">
          <img 
            class="icon-success" 
            style="margin-bottom: 28px;"
            :src="Success" 
            alt=""
          >
          <p 
            class="success-title"
            style="margin-bottom: 48px;"
          >
            {{ $t('bvPricing.cancelSuccessModal.title') }}
          </p>
          <div
            class="description"
            style="margin-bottom: 82px;"
          >
            <p>{{ $t('bvPricing.cancelSuccessModal.thankYou') }}</p>
            <p>{{ $t('bvPricing.cancelSuccessModal.sadToSeeYouGo') }}</p>
            <p v-html="t('bvPricing.cancelSuccessModal.offer')"></p>
          </div>
          <div class="schedule">
            <img 
              class="user-avatar"
              style="margin-right: 26px;"
              :src="Avatar"
            >
            <div 
              class="schedule-description"
              style="margin-right: 48px;"
            >
              <p class="title" style="margin-bottom: 14px;">{{ $t('bvPricing.cancelSuccessModal.invitationTitle') }}</p>
              <p class="content">{{ $t('bvPricing.cancelSuccessModal.invitationContent') }}</p>
            </div>
            <button
              class="book-button"
              @click="book"
            >
              <svg-icon
                name="icon_calendar"
                style="margin-right: 10px;"
                :size="24"
              />
              <span>
                {{ $t('bvPricing.cancelSuccessModal.pickTime') }}
              </span>
            </button>
          </div>
        </div>
      </el-scrollbar>
    </div>
  </el-dialog>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import { Success, Avatar } from "@/assets/images/premium";
import { useSubscriptionInfo } from "@/store/modules/user";

const props = defineProps({
  modelValue: {
    type: Boolean,
  }
});
const emits = defineEmits(["close"]);

const { t } = useI18n();
const { refresh, updateSubscriptionState } = useSubscriptionInfo();
const modalWidth = ref(1060);

const computedModalWidth = () => {
  const screenWidth = window.innerWidth;
  if (screenWidth > 4000) {
    modalWidth.value = 2500;
  }else if (screenWidth > 3000 && screenWidth < 4000) {
    modalWidth.value = 2000;
  } else if (screenWidth > 2400 && screenWidth < 3000) {
    modalWidth.value = 1500;
  } else {
    modalWidth.value = 1060;
  }
};

const book = () => {
  window.open("https://calendly.com/tinaw0601", "_blank");
};

const close = () => {
  refresh();
  updateSubscriptionState();
  emits("close");
};

onMounted(() => {
  computedModalWidth();
  window.onresize = () => {
    computedModalWidth();
  };
});
</script>

<style lang="scss">
.cancel-success-modal {
  &.el-dialog {
    width: 65%;
    height: 80%;
    min-width: 1070px;
    min-height: 500px;
  }
  
  & > .el-dialog__header {
    padding: 0;
    margin: 0;
  }

  & > .el-dialog__body {
    height: 100%;
    padding: 0px 0px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  & > .el-dialog__footer {
    padding: 20px;
  }
}
</style>

<style lang="scss" scoped>
.header-container {
  display: flex;
  justify-content: flex-end;
  padding: 22px 28px;
  margin: 0;
  border-bottom: 1px solid #e5e7eb;
}

.content-container {
  height: calc(100% - 72px);
}

.content-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 36px;

  & .icon-success {
    width: 54px;
    height: 54px;
  }

  & .success-title {
    color: #000;
    font-size: 28px;
    font-weight: 700;
    line-height: 32px;
  }
}

.description {
  width: 466px;
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; 
  user-select: text;

  & p + p {
    margin-top: 18px;
  }

  & .mark {
    color: #6741FF;
  }
}

.schedule {
  width: 840px;
  display: flex;
  align-items: center;
  padding: 47px 30px;
  border-radius: 8px;
  background: #F8F5FF;

  & > .user-avatar {
    width: 80px;
    height: 80px;
  }
}

.schedule-description {
  & > .title {
    color: #000;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
  }

  & > .content {
    width: 480px;
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
}

.book-button {
  width: 146px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 36px;
  background: linear-gradient(289deg, #632CFF 8.11%, #8E68FF 99.95%);

  & > span {
    color: #FFF;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
}
</style>