<script setup>
import { useI18n } from "vue-i18n";
import { useDebounceFn } from "@vueuse/core";
import { reactive, ref } from "vue";
import prefix_password from "@/assets/images/login/prefix_password.svg";
import prefix_password_fail from "@/assets/images/login/fail_password.svg";
import { checkIsPassword } from "../../utils.ts";
import ForgotFour from "./forgot-fourth.vue";
import { useUserStore } from "@/store/modules/user.ts";
import { useRoute } from "vue-router";

const {t} = useI18n();
const formState = reactive({
  password: "",
  rePassword: "",
});
const errorState = reactive({
  password: "success",
  rePassword: "success",
  errorMsg: "",
});
const isLoading = ref(false);
const step = ref(3);
const isCanReset = ref(false);
const user = useUserStore();
const route = useRoute();
const debouncedFn = useDebounceFn(async (value) => {
  //api
  const params = {
    email: route.query.email,
    state: route.query.token,
    password: formState.password,
  };
  const { code, msg } = await user.resetPwd(params);
  if (code === 0) {
    step.value = 4;
  } else {
    errorState.errorMsg = msg;
  }
}, 500);

const onFinish = async (values) => {
  //此处做校验
  isLoading.value = true;
  errorState.password = "success";
  errorState.rePassword = "success";
  errorState.errorMsg = "";
  await checkForm(formState);
  isLoading.value = false;
};

const checkForm = async (values) => {
  //先校验是否符合密码规范
  const { password, rePassword } = values;

  if (password !== rePassword) {
    errorState.rePassword = "error";
    errorState.errorMsg = t('account.pwTwiceCheckError');
    return;
  }

  if (!checkIsPassword(password)) {
    errorState.password = "error";
    errorState.rePassword = "error";
    errorState.errorMsg =
      t('account.pwFormatCheckError')
    return;
  }
  await debouncedFn(values);
};

const onFinishFailed = (errorInfo) => {
  console.log("Failed:", errorInfo);
};

const handleInput = (name) => {
  handlePasswordChange();
  errorState[name] = "success";
  errorState.errorMsg = "";
};

const handlePasswordChange = () => {
  if (formState.password && formState.rePassword) {
    isCanReset.value = true;
  }
};
</script>

<template>
  <div class="reset-form">
    <h1 class="text-4xl font-medium mb-8">{{ $t('account.restTitle') }}</h1>
    <el-form
      v-if="step === 3"
      :model="formState"
      class="login-form"
      name="basic"
      autocomplete="off"
      @finish="onFinish"
      @finishFailed="onFinishFailed"
    >
      <el-form-item>
        <div class="text-left mb-8 forgot-text">
          {{ $t('account.enterNewPw') }}
        </div>
      </el-form-item>
      <el-form-item
        name="email"
        class="mb-8"
        :validate-status="errorState.password"
      >
        <el-input
          type="password"
          v-model="formState.password"
          @input="handleInput('password')"
          @keyup.enter="onFinish(formState)"
          class="form-input"
          :show-password="true"
          placeholder="New Password"
        >
          <template #prefix>
            <img :src="prefix_password" />
          </template>
        </el-input>
      </el-form-item>
      <el-form-item
        name="new password"
        class="mb-8"
        :validate-status="errorState.rePassword"
      >
        <el-input
          type="password"
          v-model="formState.rePassword"
          @input="handleInput('rePassword')"
          @keyup.enter="onFinish(formState)"
          class="form-input"
          :show-password="true"
          placeholder="Re-enter New Password"
        >
          <template #prefix>
            <img :src="prefix_password" />
          </template>
        </el-input>
      </el-form-item>
      <el-form-item>
        <PrimaryButton
          class="reset-button"
          @click="onFinish(formState)"
          :disabled="!isCanReset"
          width="100%"
          >{{ $t('account.forget3.resetButton') }}
        </PrimaryButton>
      </el-form-item>
      <el-form-item>
        <div class="error-msg-box">{{ errorState.errorMsg }}</div>
      </el-form-item>
    </el-form>
    <ForgotFour v-if="step === 4" />
  </div>
</template>

<style lang="scss" scoped>
.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;

  :deep(.el-form-item) {
    width: 100%;
  }

  :deep(.el-input) {
    width: 100%;
    min-width: auto;
    max-width: 375px;
    height: 54px;
    border-color: transparent;
    border-radius: 41px;
    padding: 0;
  }

  :deep(.el-form-item__content) {
    width: 100%;
    display: flex;
    justify-content: center;
    min-height: 32px;
    flex-wrap: nowrap;
  }

  :deep(.el-input__wrapper) {
    height: 54px;
    border-radius: 41px;
    padding: 1px 11px;
  }

  :deep(.el-input__inner) {
    border-color: transparent;
    box-shadow: none;
    text-align: left;
    padding: 0;
    font-size: 14px;
    color: #000;
  }

  :deep(.el-input__prefix-inner > :last-child) {
    margin-right: 12px;
    margin-left: 10px;
  }

  :deep(.el-input__suffix-inner) {
    margin: 0 13px 0 10px;
  }

  :deep(.el-input__wrapper.is-focus) {
    box-shadow: 0 0 0 1px #875eff inset !important;
  }
}

.reset-button {
  width: 100%;
  display: flex;
  justify-content: center;

  :deep(.el-button) {
    width: 100%;
    min-width: auto;
    max-width: 375px;
    height: 54px;
    border-radius: 36px;
    // background: linear-gradient(289deg, #632cff 8.11%, #8e68ff 99.95%);
  }
}

.forgot-text {
  width: 570px;
  text-align: center;
}

.error-msg-box {
  font-weight: 400;
  max-width: 375px;
  color: #f54a45;
}
</style>
