import i18n from "@/i18n";
const {t} = i18n.global;
export const resetPwd = {
  forgot: {
    title: t('account.forgot-your-password'),
    describe:
      t('account.forgetDesc'),
    button: t('account.fogetbutton'),
  },
  reset: {
    title: t('account.restTitle'),
    describe:
      t('account.resetDesc'),
    button: t('account.resetButton'),
  },
};
