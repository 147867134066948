<template>
  <el-dialog
    class="subscribe-container"
    align-center
    destroy-on-close
    v-model="dialogVisible"
    :append-to-body="true"
    :show-close="false"
    @close="handleCloseDialog"
  >
    <component
      :is="tabs[currentTab]" 
      :showIntroduction="showIntroduction"
      @close="handleCloseDialog"
    />
  </el-dialog>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';
import BoolvideoPremium from "./premium/index.vue";
import SimilarPremium from "./svpremium/index.vue";

const emit = defineEmits(['close', 'refresh']);
const props = defineProps({
  visible: Boolean,
  showIntroduction: Boolean,
});

const tabs = {
  1: BoolvideoPremium,
  2: SimilarPremium,
};
const currentTab = inject("globalDomain");
const modalWidth = ref(1060);
const dialogVisible = ref(props.visible);

watch(
  () => props.visible,
  (visible) => {
    dialogVisible.value = visible;
  },
  {
    immediate: true,
  }
);

const handleCloseDialog = () => {
  dialogVisible.value = false;
  emit('close', true);
};
</script>

<style lang="scss" scoped>
.card-part {
  background: #e5eaff;
  height: 200px;
}
</style>

<style lang="scss">
.subscribe-container {
  &.el-dialog {
    width: 65%;
    height: 80%;
    min-width: 1070px;
    min-height: 500px;

    & > .el-dialog__header {
      padding: 0;
      margin: 0;
    }

    & > .el-dialog__body {
      height: 100%;
      padding: 0px 0px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    & > .el-dialog__footer {
      padding: 20px;
    }
  }

  .el-scrollbar__wrap {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
</style>
