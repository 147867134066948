<template>
  <header :class="viewStore.header.theme">
    <div class="header-content bg-white">
      <nav class="flex items-center">
        <RouterLink
          to="/"
          v-html="globalDomain == 2 ? similarVideo_logo : logo"
          class="boolv-logo cursor-pointer"
        />
        <ul
          class="menu-list"
         
          @pointermove="handlePointerMove"
          @pointerenter="canCloseMenu = false"
          @pointerleave="handlePointerLeave"
        >
          <li
            v-for="menu of headerMenuList"
            :key="menu.key"
            :data-name="menu.key"
            :data-has-items="'items' in menu ? '' : null"
            :class="{
              dropdown: 'items' in menu,
              active: activeMenu?.key === menu.key,
              'text-textColor': activeMenu !== null,
            }"
          >
            <router-link v-if="menu.link" :to="menu.path" :query="menu.query">{{ menu.name }}</router-link>
            <p  @click="handlePath(menu)" v-else>{{ menu.name }}</p>
          </li>
        </ul>
      </nav>

      <Avatar v-if="isLogin" />
      <div v-else style="display: flex">
        <common-button
          class="login-button"
          type="text"
          plain
          size="extra-small"
          @click="router.push({ name: 'Login' })"
        >
          {{ $t("home.header.login") }}
        </common-button>
        <common-button
          plain
          class="signup-button"
          size="extra-small"
          @click="router.push({ name: 'Signup' })"
        >
          {{ $t("home.header.signup") }}
        </common-button>
      </div>
    </div>
    <div
      class="menu-items-container"
      @pointerleave="handlePointerLeave"
      @pointerenter="canCloseMenu = false"
    >
      <ul
        :class="['menu-items', usedActiveMenu.type === 'group' ? 'multi-col' : '']"
        v-if="usedActiveMenu !== null && usedActiveMenu.type !== 'noItems'"
        :style="{ transform: `translateX(${menuOffset}px)` }"
      >
        <template v-if="usedActiveMenu.type === 'items'">
          <li v-for="(item, i) of usedActiveMenu.target.items" :data-i="i" >
            <router-link v-if="item.link" :to="item.path" :query="item.query">{{ item.name }}</router-link>
            <p @click="handlePath(item)" v-else>{{ item.name }}</p>
          </li>
        </template>
        <template v-else>
          <li v-for="(items, key) in usedActiveMenu.target">
            <b>{{ key }}</b>
            <ul>
              <li v-for="item of items" :data-i="item.index">
               <router-link v-if="item.link" :to="item.path" :query="item.query">{{ item.name }}</router-link>
               <p @click="handlePath(item)" v-else>{{ item.name }}</p>
              </li>
            </ul>
          </li>
        </template>
      </ul>
    </div>
  </header>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useRouter } from "vue-router";
import { headerMenuList } from "./data";
import { isLogin } from "@/utils/auth";
import { useViewStore } from "@/store/view";
import logo from "@/assets/icons/common/icon_title_logo.svg?raw";
import similarVideo_logo from '@/assets/similar-video/similarVideo_logo.svg?raw';
import commonButton from "@/components/common/bv-button/components/common-button.vue";
import Avatar from "../avatar.vue";
import type { HeaderMenu, Menu, MenuItem, GroupMenuItem } from "./data";

const globalDomain = inject('globalDomain')
let canCloseMenu = true;
const activeMenu = ref<HeaderMenu | null>(null);
const menuOffset = ref(0);
const router = useRouter();
const viewStore = useViewStore();


const usedActiveMenu = computed(() => {
  if (activeMenu.value === null) return null;
  if (!("items" in activeMenu.value)) return {
    type: 'noItems' as const,
    target: activeMenu.value as MenuItem,
  };

  const group: Record<string, Array<GroupMenuItem & {index: number}>> = {};
  for (let i = 0; i < activeMenu.value.items.length; i++) {
    const item: GroupMenuItem = activeMenu.value.items[i];
    const key = item.group || "_";
    const usedItem = {...item, index: i};
    
    group[key] ??= [];
    group[key].push(usedItem);
  }

  const keys = Object.keys(group);
  return (keys.length === 1 && keys[0] === "_") ? {
    type: 'items' as const,
    target: activeMenu.value as Menu,
  } : {
    type: 'group' as const,
    target: group,
  };
});

function handlePointerMove(e: PointerEvent) {
  const target = e.target as HTMLElement;
  if (target.tagName !== "LI") return;

  const key = target.dataset.name!;
  if (key === activeMenu.value?.key) return;

  const foucusMenu = headerMenuList.find((menu) => menu.key === key);
  if (foucusMenu === undefined) {
    activeMenu.value = null;
  } else {
    const targetBoundingClientRect = target.getBoundingClientRect();

    menuOffset.value = targetBoundingClientRect.left + 16;
    activeMenu.value = foucusMenu;
  }
}

function handlePointerLeave(e: PointerEvent) {
  canCloseMenu = true;

  setTimeout(() => {
    if (canCloseMenu) {
      activeMenu.value = null;
    }
  });
}

function handleClickNav(e: MouseEvent) {

  const targetElement = e.target as HTMLElement;
  if (targetElement.tagName !== "LI") return;

  let targetNav: MenuItem | undefined = undefined;
  if (targetElement.hasAttribute("data-i")) {
    const i = targetElement.dataset.i!;

    targetNav = (activeMenu.value as Menu).items[Number(i)];
  } else if (!targetElement.hasAttribute("data-has-items")) {
    targetNav = activeMenu.value as MenuItem;
  }

  if (!targetNav) return;
  if ("handler" in targetNav) {
    targetNav.handler(router);
  } else {
    const path = targetNav.path;
    if (/^[a-z]+:/.test(path)) {
      window.open(path);
    } else {
      router.push({ path, query: targetNav.query });
    }
  }
}

const handlePath = (item: any) => {
  const path = item.path
  if(item.handler ) {
    item.handler()
  }else if((/^[a-z]+:/.test(path))) {
     window.open(path);
  }else {
    router.push({ path, query: item.query });
  }
}
</script>

<style lang="scss" scoped>
header {
  position: fixed;
  width: 100%;
  height: 60px;
  font-size: 14px;
  z-index: 1000;

  &.dark {
    & > .header-content {
      background-color: #000000;
      color: #ffffff;
    }

    & > .menu-items-container {
      border-color: #323232;
    }
  }

  &.light,
  &:hover {
    & > .header-content {
      color: #060606;
      background-color: #ffffff;
    }

    & > .menu-items-container {
      border-color: #dee0e3;
    }
  }
}

.header-content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 26px;
}

.sign-up-button {
  border: 1px solid #fff;
  border-radius: 60px;
}

.boolv-logo {
  height: 36px;
  margin-right: 24px;
}

.menu-list {
  display: flex;
  align-items: center;

  & > li {
    display: flex;
    height: 60px;
    padding-inline: 16px;
    align-items: center;
    cursor: pointer;

    &[data-has-items] {
      cursor: default;
    }
  }

  & > li.active {
    background-color: rgba(0, 0, 0, 0.1);
  }

  & > .dropdown::after {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-left: 6px;
    background: url("@/assets/icons/home/arrow_down.svg") no-repeat center;
    color: #fff;
  }

  & > .active.dropdown::after {
    transform: rotate(180deg);
  }
}

.login-button > :deep(button.el-button) {
  color: inherit;
  transition: none;
}

.signup-button > :deep(button.el-button) {
  color: #060606;
  border-color: #bbbfc4;
}

.menu-items-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  border-top: 1px solid #dee0e3;
  background-color: #ffffff;
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
  z-index: -1;
}

.menu-items {
  width: fit-content;
  padding-block: 19px 27px;

  li[data-i] {
    width: fit-content;
    padding-block: 9px;
    cursor: pointer;

    &:hover {
      color: #875eff;
    }
  }
}

.multi-col {
  display: flex;
  gap: 70px;

  & > li {
    & > b {
      font-weight: 500;
    }

    & > ul {
      margin-top: 9px;
      color: rgb(100, 106, 115);
    }
  }
}
</style>
