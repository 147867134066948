<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import { ref, watch } from "vue";
import CodeInput from "./code-input.vue";
import returnPrevious from "@/assets/images/login/return_previous.svg";
import { useUserStore, useSubscriptionInfo } from "@/store/modules/user";
import { useTrackStore } from "@/store/modules/track";
import { useRouter, useRoute } from "vue-router";
import { type } from "os";

const {t} = useI18n();
const globalDomain = inject('globalDomain');
const router = useRouter();
const route = useRoute();

interface Props {
   type: String,
  count: number;
  formState: {
    name: string;
    email: string;
    password: string;
  };
}
const props = defineProps<Props>();

const emit = defineEmits(['loginSuccess'])

const borderColor = ref("#D0D0D0");
const errorMsg = ref("");
const code = ref("");
const codeButtonDisabled = ref(true);
const btnLoading = ref(false);

const user = useUserStore();
const subscriptionInfo = useSubscriptionInfo();
const { track } = useTrackStore();

watch(code, () => {
  if (code.value.length !== 4) {
    codeButtonDisabled.value = true;
  } else {
    codeButtonDisabled.value = false;
  }
});

onMounted(() => {
  document.addEventListener("keyup", keyUp);
});

onBeforeUnmount(() => {
  document.removeEventListener("keyup", keyUp);
});

const keyUp = (e: KeyboardEvent) => {
  e.stopPropagation();
  if (e.code === "Enter") {
    handleLogin();
  }
};

const handleLogin = async () => {
  if (code.value.length !== 4) {
    borderColor.value = "red";
    errorMsg.value = "Please enter code";
    return;
  }
  if(btnLoading.value) return;
  btnLoading.value = true;
  //login api post
  const params = {
    verifyCode: code.value,
    username: props.formState?.name,
    email: props.formState?.email,
    password: props.formState?.password,
  };
  const result: any = await user.register(params);
  const { msg } = result;
  if (result.code !== 0) {
    borderColor.value = "red";
    errorMsg.value = msg;
  } else {
    await subscriptionInfo.refresh();
    track("boolvideo_signup_success");
    // window.fpr("referral", { email: props.formState.email });
    if (window.tolt_referral) {
      window.tolt.signup(props.formState.email)
    }

    if(props.type == 'modal') {
      emit('loginSuccess')
      return
    }
    const redirect = route.query?.redirect;
    const query = route.query;
    if (redirect) {
      delete query.redirect;
      router.push({ path: redirect, query });
    } else {
      router.push("/workspace");
    }
  }
  btnLoading.value = false;
};

const handleChange = (val: string) => {
  if (val.length === 4) {
    borderColor.value = "#D0D0D0";
    errorMsg.value = "";
    code.value = val;
  }
};
</script>

<template>
  <el-form class="login-form flex flex-1 justify-center items-center flex-col">
    <el-form-item class="mb-4">
      <h1 class="text-black text-2xl font-medium">{{ $t('account.verificationCode') }}</h1>
    </el-form-item>
    <el-form-item class="mb-52">
      <div class="text-gray-400">
        {{ $t('account.verification.desc') }}
      </div>
    </el-form-item>
    <el-form-item class="mb-3">
      <CodeInput @complete="handleLogin" @change="handleChange" :fields="4" :fieldWidth="54" :fieldHeight="54"
        :required="false" :borderColor="borderColor" />
    </el-form-item>
    <el-form-item class="mb-3">
      <div class="error-msg-box">{{ errorMsg }}</div>
    </el-form-item>
    <el-form-item class="mb-5">
      <button class="customize-button" type="button" @click.stop="$emit('reSend')" :style="{
        color: count > 0 ? '#8F959E' : '#060606',
        cursor: count > 0 ? 'not-allowed' : 'pointer',
      }">
        {{ $t('account.verification.resendCode') }} {{ count > 0 ? `( ${count}S )` : "" }}
      </button>
    </el-form-item>
    <el-form-item class="mb-7">
      <el-button type="primary" class="continue-button text-white flex justify-center items-center" @click="handleLogin"
        :loading="btnLoading" :disabled="codeButtonDisabled">
        <div class="flex items-center">
          <p>{{globalDomain == 1 ? t('account.signupBvButton') : t('account.signSvButton')}}</p>
        </div>
      </el-button>
    </el-form-item>
    <el-form-item class="mb-3">
      <button class="return-Previous rounded-full flex items-center justify-center cursor-pointer" type="button"
        @click="$emit('returnPrevious')">
        <img :src="returnPrevious" />
      </button>
    </el-form-item>
  </el-form>
</template>

<style lang="scss" scoped>
.login-form {
  width: 375px;

  :deep(.el-form-item) {
    width: 375px;
  }

  :deep(.modal-margin.el-form-item) {
    margin-bottom: 0px !important;
  }

  :deep(.el-form-item__content) {
    display: flex;
    justify-content: center;
    min-height: 32px;
    flex-wrap: nowrap;
  }
}

.return-Previous {
  width: 48px;
  height: 48px;
  border: 1px solid #d0d0d0;
  margin: 0 auto;

  &:hover {
    background: #dee0e3;
  }
}

.continue-button {
  width: 100%;
  height: 54px;
  border-radius: 36px;
  // background: linear-gradient(289deg, #632cff 8.11%, #8e68ff 99.95%);
}

.mb-52 {
  margin-bottom: 50px;
}

.error-msg-box {
  font-weight: 400;
  max-width: 375px;
  color: #f54a45;
}

.customize-button {
  width: 100%;
  height: 54px;
  border-radius: 36px;
  border: 1px solid #d0d0d0;
}
</style>
