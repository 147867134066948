import i18n from "@/i18n";

const {t} = i18n.global;
export const validateEmail = (email) => {
  if (String(email).toLowerCase().includes("@")) {
    return true;
  } else {
    return false;
  }
  // const pattern = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
  // const isEmail = pattern.test(email);
  // return isEmail;
};

// 不少于8位字符，且必须包含一个数字和一个特殊字符
export const passwordPattern =
  /^(?=.*[0-9])(?=.*[A-Za-z])[A-Za-z\d~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]{6,32}/;
export const checkIsEmail = (email) => {
  const pattern = /^.*@.*$/;
  const isEmail = pattern.test(email);
  return isEmail;
};

export const checkIsPassword = (password) => {
  const isPassword = passwordPattern.test(password);
  return isPassword;
};

export const checkTwoPassword = (password, newPassword) =>
  password === newPassword;

export const checkFormFn = {
  email: checkIsEmail,
  password: checkIsPassword,
  newPassword: checkIsPassword,
};

export const emptyMessageList = {
  name: t('account.emptyNameError'),
  email: t('account.emptyEmailError'),
  password: t('account.emptyPwError'),
  newPassword: t('account.emptyNewPwError'),
  part: t('account.emptyPartError'),
  all: t('account.emptyAllError'),
};
export const errorMessageList = {
  email: t('account.emailError'),
  password:
    t('account.pwError'),
  newPassword:
    t('account.pwError'),
  more: t('account.moreError'),
};

export const checkForm = (values, isNotCheckFormate) =>
  new Promise((resolve, reject) => {
    const keys = Object.keys(values);
    let message = "";
    const emptyKey = [];
    const errorKey = [];
    for (let i = 0; i < keys.length; i += 1) {
      if (!values[keys[i]]) {
        emptyKey.push(keys[i]);
      } else {
        const checkResult =
          checkFormFn[keys[i]] && checkFormFn[keys[i]](values[keys[i]]);
        if (checkResult === false) {
          errorKey.push(keys[i]);
        }
      }
    }
    if (emptyKey.length) {
      if (emptyKey.length === keys.length && emptyKey.length >= 3) {
        message = emptyMessageList.all;
      } else if (emptyKey.length > 2 && emptyKey.length < keys.length) {
        message = emptyMessageList.part;
      } else if (emptyKey.length === 2) {
        const emptyStr = emptyKey.join(" and ");
        message = `Please complete the ${emptyStr}`;
      } else {
        message = emptyMessageList[emptyKey[0]];
      }
      reject({
        errorFiles: [...emptyKey],
        message,
      });
    } else {
      if (isNotCheckFormate) {
        resolve();
      }
      if (!errorKey.length) {
        resolve();
      }

      message = errorMessageList[errorKey[0]];
      reject({
        errorFiles: [...errorKey],
        message,
      });
    }
  });

export const checkField = () => new Promise(() => {});
