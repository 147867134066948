<template>
  <div class="py-6" v-if="!data.couponValue && !deleteLoading">
    <div class="promotion-plan" :data-error-msg="errorMsg ? errorMsg : ''">
      <div class="promotion-code-entry">
        <div
          :class="[
            'promotion-code-entry-inputWrapper',
            !promotionVisible && 'promotion-code-entry-inputWrapper-blur',
          ]"
        >
          <input
            :class="[
              'checkout-input',
              promotionVisible ? 'checkout-input-focus' : 'checkout-input-blur',
              promotionVisible && !promotionValue && `checkout-input-noValue`,
              promotionVisible && errorMsg && 'checkout-input-disabled',
            ]"
            v-model="promotionValue"
            type="text"
            placeholder="Add promo code"
            ref="inputRef"
            @input="handlePromotionInput"
            @focus="handlePromotionFocus"
            @blur="handlePromotionBlur"
          />
          <button
            @click="handleSubmit"
            :class="[
              promotionValue && !submitLoading
                ? 'promotion-code-entry-applyButton-visible'
                : '',
              'promotion-code-entry-applyButton',
            ]"
          >
            <div
              class="flex justify-center items-center"
              v-show="!submitLoading"
            >
              <span> {{ $t('bvPricing.promotion.submit') }} </span>
            </div>
          </button>
          <div class="flex justify-center items-center submit-loading">
            <img :src="icon_loading" alt="" v-show="submitLoading" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="my-5 flex justify-between" v-else>
    <p class="px-2 py-2 bg-primaryBgColor rounded-sm flex w-fit">
      <span class="flex mr-1">
        <img width="12" :src="icon_coupon" alt="" />
      </span>
      <span>
        {{ data.couponValue }}
      </span>
      <span class="flex items-center ml-1">
        <img
          class="hover:bg-closeBtnHoverColor rounded-sm cursor-pointer"
          width="16"
          height="16"
          @click="handleDelete"
          :src="icon_close"
          alt=""
          v-show="!deleteLoading"
        />
        <img
          class="delete-loading"
          :src="icon_loading"
          alt=""
          v-show="deleteLoading"
        />
      </span>
    </p>
    <p class="py-2" v-if="discount">
      {{ "-" + discount }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { ref, defineProps } from "vue";
import { icon_close } from "@/assets/icons/common";
import { icon_loading, icon_coupon } from "@/assets/images/premium";
import match from "@/utils/match";
import type { Ref } from "vue";

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  discount: {
    type: String,
    required: true,
  },
  update: {
    type: Function as PropType<(couponCode: string) => void>,
    default: () => {},
  },
});
const inputRef: Ref<HTMLElement | null> = ref(null);
const errorMsg = ref("");
const promotionValue = ref("");
const promotionVisible = ref(false);
const deleteLoading = ref(false);
const submitLoading = ref(false);

const initPrmotionState = () => {
  submitLoading.value = false;
  promotionVisible.value = false;
  promotionValue.value = "";
  errorMsg.value = "";
};

const handlePromotionInput = () => {
  errorMsg.value = "";
  const value: string = promotionValue.value
    .split("")
    .reduce((value: string, char: string): string => {
      return value + convertToValidChar(char.charCodeAt(0));
    }, "");
  promotionValue.value = value;
};

const handlePromotionFocus = () => {
  promotionVisible.value = true;
};

const handlePromotionBlur = () => {
  if (!promotionValue.value) {
    initPrmotionState();
  }
};

const convertToValidChar = (code: any) => {
  if (match.digit(code) || match.uppercase(code)) {
    return String.fromCodePoint(code);
  } else if (match.lowercase(code)) {
    return String.fromCodePoint(code - 32);
  } else {
    return "";
  }
};

const handleSubmit = async () => {
  submitLoading.value = true;
  inputRef.value?.setAttribute("disabled", "true");
  const res = await props.update(promotionValue.value);
  if (res?.success) {
    if (!res.data.couponCheckSuccessBoolean) {
      errorMsg.value = res.data.couponCheckFailMsg;
    }
  }
  submitLoading.value = false;
  inputRef.value?.removeAttribute("disabled");
};

const handleDelete = async () => {
  deleteLoading.value = true;
  await props.update("");
  deleteLoading.value = false;
};
</script>

<style scoped lang="scss">
.promotion-code-entry {
  display: inline-block;
  width: 100%;
}

.promotion-code-entry-inputWrapper {
  position: relative;
}

.promotion-code-entry-inputWrapper-blur {
  transition-property: min-width;
}

.promotion-plan {
  &:after {
    content: attr(data-error-msg);
    display: block;
    font-size: 14px;
    line-height: 1;
    text-align: left;
    color: #f54a45;
    margin-top: 8px;
    margin-left: 12px;
  }
}

.promotion-code-entry-applyButton {
  position: absolute;
  background: transparent;
  border: none;
  bottom: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 12px;
  top: 0;
  -webkit-transform: scale(0.5);
  -ms-transform: scale(0.5);
  transform: scale(0.5);
  transition:
    opacity 0.3s ease,
    -webkit-transform 0.3s ease;
  transition:
    opacity 0.3s ease,
    transform 0.3s ease;
  transition:
    opacity 0.3s ease,
    transform 0.3s ease,
    -webkit-transform 0.3s ease;
  z-index: 100;
  color: #875eff;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

.promotion-code-entry-applyButton-visible {
  cursor: pointer;
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.checkout-input {
  line-height: 22px;
  height: auto;
  border-radius: 4px;
  font-size: 14px;
  background: #ffffff;
  overflow: visible;
  border: 1px solid #d5d6d7;
  box-shadow:
    0 0 0 1px #e0e0e0,
    0 2px 4px 0 rgba(0, 0, 0, 0.07),
    0 1px 1.5px 0 rgba(0, 0, 0, 0.05);
  cursor: pointer;
  transition:
    background-color 0.5s,
    border-color 0.5s,
    box-shadow 0.5s,
    padding 0.5s;
  color: #000;

  &::-webkit-input-placeholder {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    font-size: 14px;
    color: #875eff;
    transition:
      left 0.5s,
      top 0.5s,
      color 0.5s;
  }

  &:focus {
    outline: none;
    z-index: 2;
    box-shadow:
      0 0 0 1px rgba(135, 94, 255, 0.3),
      0 1px 1px 0 rgba(0, 0, 0, 0.07),
      0 0 0 4px rgba(135, 94, 255, 0.3);
  }
}

.checkout-input-focus {
  width: 100%;
  padding: 8px 90px 8px 12px;
  font-weight: 300;
  cursor: text;
  line-height: 36px;
  height: 36px;
  color: #000;

  &::-webkit-input-placeholder {
    left: 12px;
    color: #aaa;
  }
}

.checkout-input-blur {
  padding: 0;
  background-color: transparent;
  box-shadow: none;
  border-color: transparent;
}

.checkout-input-noValue {
  width: 100%;
  cursor: text;
  padding: 8px 12px;
}

.checkout-input-disabled {
  // padding: 0;
  background-color: transparent;
  border-color: #f54a45;
  box-shadow: none !important;
}

.submit-loading {
  position: absolute;
  bottom: 0;
  right: 12px;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 18px;
    animation: rotate 2s linear infinite;
  }
}

.delete-loading {
  width: 16px;
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
