import i18n from "@/i18n";

const { t } = i18n.global;
// Card Init copywriting
export const freePriorityList = [
  t('svPricing.free-priority-1'),
  t('svPricing.free-priority-2'),
  t('svPricing.free-priority-3'),
  t('svPricing.free-priority-4'),
  t('svPricing.free-priority-5'),
  t('svPricing.free-priority-6'),
];

export const freePlanDescription =
  "";

export const freePriorityDescription = "";

export const starterPriorityList = [
  t('svPricing.starter-priority-1'),
  t('svPricing.starter-priority-2'),
  t('svPricing.starter-priority-3'),
  t('svPricing.starter-priority-4'),
  t('svPricing.starter-priority-5'),
];

export const starterPlanDescription =
  t('svPricing.starter-plan-desc');

export const starterPriorityDescription = "";

export const standardPriorityList = [
  t('svPricing.standard-priority-1'),
  t('svPricing.standard-priority-2'),
  t('svPricing.standard-priority-3'),
  t('svPricing.standard-priority-4'),
  t('svPricing.standard-priority-5'),
  t('svPricing.standard-priority-6'),
];

export const standardPlanDescription =
  t('svPricing.standard-plan-desc');

export const standardPriorityDescription = "";

export const proPriorityList = [
  t('svPricing.pro-priority-1'),
  t('svPricing.pro-priority-2'),
  t('svPricing.pro-priority-3'),
  t('svPricing.pro-priority-4'),
  t('svPricing.pro-priority-5'),
  t('svPricing.pro-priority-6'),
];

export const proPlanDescription =
  t('svPricing.pro-plan-desc');

export const proPriorityDescription = t('svPricing.pro-priority-desc');

export const ultraPriorityList = [
  t('svPricing.ultra-priority-1'),
  t('svPricing.ultra-priority-2'),
  t('svPricing.ultra-priority-3'),
  t('svPricing.ultra-priority-4'),
  t('svPricing.ultra-priority-5'),
];

export const ultraPlanDescription =
  t('svPricing.ultra-plan-desc');

export const ultraPriorityDescription = t('svPricing.ultra-priority-desc');

export const faqList = [
  {
    title: t('svPricing.faq-1-title'),
    content: t('svPricing.faq-1-content'),
  },
  // {
  //   title: "What is a three day trial period?",
  //   content: `A three day trial period serves as a hesitation period, allowing new subscribers to fully access membership benefits without immediate financial commitment. During this period, users can evaluate our services and may cancel their subscription at any time within these three days without incurring charges. If the subscription is not canceled at the end of the trial period, it will be charged according to our terms and no refunds will be issued.`,
  // },
  {
    title: t('svPricing.faq-2-title'),
    content: t('svPricing.faq-2-content'),
  },
  {
    title: t('svPricing.faq-3-title'),
    content: t('svPricing.faq-3-desc'),
  },
  {
    title: t('svPricing.faq-4-title'),
    content: t('svPricing.faq-4-content'),
  },
  {
    title: t('svPricing.faq-5-title'),
    content:
      t('svPricing.faq-5-content'),
  },
];

export const cancelTips = {
  300401: [
    "Can't export videos anymore",
    "Lose 100 voiceover transcription mins",
    "Lose 100 AI-generated credits",
    "Lose 10 starter templates",
    "Lose 3 brand kits",
    "Lose 5G cloud storage",
  ],
  300201: [
    "Can't export videos anymore",
    "Can't use AI tools anymore",
    "Lose 600 voiceover transcription mins",
    "Lose 500 AI-generated credits",
    "Lose 100+ standard templates",
    "Lose 3 brand kits",
    "Lose 10G cloud storage",
  ],
  300301: [
    "Can't export videos anymore",
    "Can't use AI tools anymore",
    "Lose 1500 voiceover transcription mins",
    "Lose 5000 AI-generated credits",
    "Lose 500+ premium  templates",
    "Lose  unlimited brand kits",
    "Lose 100G cloud storage",
  ],
  300402: [
    "Can't export videos anymore",
    "Lose 100 voiceover transcription mins",
    "Lose 100 AI-generated credits",
    "Lose 10 starter templates",
    "Lose 3 brand kits",
    "Lose 5G cloud storage",
  ],
  300202: [
    "Can't export videos anymore",
    "Can't use AI tools anymore",
    "Lose 600 voiceover transcription mins",
    "Lose 500 AI-generated credits",
    "Lose 100+ standard templates",
    "Lose 3 brand kits",
    "Lose 10G cloud storage",
  ],
  300302: [
    "Can't export videos anymore",
    "Can't use AI tools anymore",
    "Lose 1500 voiceover transcription mins",
    "Lose 5000 AI-generated credits",
    "Lose 500+ premium  templates",
    "Lose  unlimited brand kits",
    "Lose 100G cloud storage",
  ],
};
