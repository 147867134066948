import { _get, _post, _singleGet } from "@/utils/https";
const urlList = {
  import: "/import/url",
  render: "/video/render/product",
  reGenerate: "/video/render/new",
  export: "/video/export/preview_page",
  editExport: "/video/export/edit_page",
  videoPurpose: "/form/purposeV1",
};

interface GetDraftDetailResponse {
  draftId?: string;
  ratio: string;
  reqId: string;
  rid: string;
  tags: string[];
  templateUrl: string;
  templateId: string;
  duration?: number;
  assets: [];
  renderConfig?: string;
}

type VideoToDraft = {
  rid: string | number
}

const { VITE_API_BASE } = import.meta.env;

//导入链接
export function importUrl(params: CreateVideoReqParams.ImportUrl) {
  return _get<CreateVideoResData.ImportUrlType>(
    VITE_API_BASE + urlList.import,
    params,
  );
}

//视频目的
export function videoPurpose() {
  return _get<CreateVideoResData.PurposeType[]>(
    VITE_API_BASE + urlList.videoPurpose,
  );
}

//product to video
export function productVideoRender(params: CreateVideoReqParams.VideoRenderParams) {
  return _post<CreateVideoResData.VideoRenderType>(
    VITE_API_BASE + '/video/render/product',
    params,
  );
}

export function productVideoScript(params: CreateVideoReqParams.ScriptParams) {
  return _post<CreateVideoResData.VideoRenderScriptType[]>(
    VITE_API_BASE + '/video/render/boolv/script',
    params,
  );
}

//template to video
export function templateVideoRender(params: CreateVideoReqParams.VideoRenderParams) {
  return _post<CreateVideoResData.VideoRenderType>(
    VITE_API_BASE + '/video/render/template',
    params,
  );
}

//visuals To Video 
export function visualsToVideoRender(params: CreateVideoReqParams.VideoRenderParams) {
  return _post<CreateVideoResData.VideoRenderType>(
    VITE_API_BASE + '/video/render/visuals',
    params,
  );
}


export function videoRenderState(reqId: string) {
  return _get<CreateVideoResData.VideoRenderType>(
    VITE_API_BASE + '/video/render/state',
    { reqId },
  );
}


//重新渲染
export function generateVideo(
  params: CreateVideoReqParams.GenerateVideoParams,
) {
  return _get<CreateVideoResData.RenderVideoType>(
    VITE_API_BASE + urlList.reGenerate,
    params,
  );
}

// 获取版本渲染信息
export function getRenderInfos(reqId: string, version: number) {
  return _singleGet<CreateVideoResData.RenderVideoType[]>(
    VITE_API_BASE + `/video/render/${reqId}/${version}/infos`,
  );
}

// 获取版本渲染信息
export function versionList(reqId: string) {
  return _singleGet<CreateVideoResData.VersionType>(
    VITE_API_BASE + `/video/render/${reqId}/versions`,
  );
}

// 重新生成
export function reGenerate(params: CreateVideoReqParams.GenerateVideoParams) {
  return _post<CreateVideoResData.RenderVideoType[]>(
    VITE_API_BASE + urlList.reGenerate,
    params,
  );
}

// 预览视频导出
export function exportVideo(params: CreateVideoReqParams.ExportVideoParams) {
  return _post<string>(VITE_API_BASE + urlList.export, params);
}

// 编辑视频导出
export function exportEditVideo(
  params: CreateVideoReqParams.EditExportVideoParams,
) {
  return _post<string>(VITE_API_BASE + urlList.editExport, params);
}

// 草稿详情
export async function getEditDraftDetail(draftId: string) {
  return _get<GetDraftDetailResponse>(
    `${VITE_API_BASE}/draft/${draftId}/render_info`,
  );
}

// 获取单个assetInfo
export async function getSingleAssetInfo(rid: string) {
  return _get<CreateVideoResData.RenderVideoType>(
    `${VITE_API_BASE}video/render/${rid}/info`,
  );
}

// 获取单个assetInfo
export async function getFormCaseList() {
  return _get(`${VITE_API_BASE}/form/case`);
}

export async function videoToDrafts(params: VideoToDraft ) {
  return _post(`${VITE_API_BASE}/video/render/to_drafts`, params);
}

export async function getStyleGroup(params) {
  return _get(`${VITE_API_BASE}/form/styles`, params);
}

