<template>
  <component 
    :is="pageMap[currentPage]" 
    @next="handleToNext" 
    @close="handleClose" 
    :packageCode="currentPackageCode" 
    :subscriptionType="currentSubscriptionType" 
    :state="currentState" 
  />
</template>

<script setup>
import { ref, watch } from "vue";
import Payment from "../payment/payment.vue";
import Pricing from "../pricing/pricing.vue";
import { usePricingStore } from "../../stores";

const props = defineProps({
  currentPath: String
})

const emit = defineEmits(['next', 'close'])

const { getPricingCardData } = usePricingStore();

const pageMap = {
  payment: Payment,
  premium: Pricing,
};

const currentPage = ref("premium");
const currentPackageCode = ref('')
const currentSubscriptionType = ref('')
const currentState = ref('view')

watch(
  () => props.currentPath, 
  (path) => {
    currentPage.value = path;
  }
);

const handleToNext = ({path, packageCode, subscriptionType, state='' }) => {
  currentPage.value = path;
  currentPackageCode.value = packageCode;
  currentSubscriptionType.value = subscriptionType;
  currentState.value = state;
}

onMounted(() => {
  getPricingCardData();
});

const handleClose = () => {
  emit('close')
};

</script>

<style lang="scss" scoped></style>
