<template>
  <el-tooltip
    :visible="visible"
    :transition="transition"
    :content="content"
    :raw-content="rawContent"
    :placement="placement"
    :popper-options="popperOptions"
  >
    <div
      class="bv-tooltip-content-container"
      :class="class"
      @mouseenter="mouseEnter"
      @mouseleave="mouseLeave"
      @mousedown="visible = false"
    >
      <slot></slot>
    </div>
  </el-tooltip>
</template>
<script setup>
const props = defineProps({
  transition: {
    type: String,
    default: "none",
  },
  content: {
    type: String,
    default: "",
  },
  showAfter: {
    type: Number,
    default: 1000,
  },
  hideAfter: {
    type: Number,
    default: 0,
  },
  placement: {
    type: String,
    default: "bottom",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  class: {
    type: String,
  },
  rawContent: {
    type: Boolean,
    default: false,
  },
  popperOptions: {
    type: Object,
    default: {},
  }
});
const showTimer = ref(null);
const hideTimer = ref(null);
const visible = ref(false);

onBeforeUnmount(cleanup);

function mouseEnter() {
  cleanup();
  showTimer.value = setTimeout(() => (visible.value = true), props.showAfter);
}

function mouseLeave() {
  cleanup();
  hideTimer.value = setTimeout(() => (visible.value = false), props.hideAfter);
}

function cleanup() {
  if (showTimer.value) {
    clearTimeout(showTimer.value);
  }
  if (hideTimer.value) {
    clearTimeout(hideTimer.value);
  }
  showTimer.value = null;
  hideTimer.value = null;
}

watch(visible, () => {
  if (props.disabled) {
    visible.value = false;
  }
});
</script>
<style>
.bv-tooltip-content-container {
  width: 100%;
  height: 100%;
}
</style>
