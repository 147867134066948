import { h } from "vue";
import dayjs from "dayjs";
import type { VNode } from "vue";
import emojiRegex  from "emoji-regex";

export * from "./common";
export * from "./type";
export * from "./hook";
export * from "./dom";
export * from "./request";

interface Size {
  width: number;
  height: number;
}

export const urlToBase64 = (url: string) => {
  return new Promise((resolve, reject) => {
    let image = new Image();
    image.onload = function () {
      let canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;
      // 将图片插入画布并开始绘制
      canvas.getContext("2d")!.drawImage(image, 0, 0);
      // result
      let result = canvas.toDataURL("image/png");
      resolve(result);
    };
    // CORS 策略，会存在跨域问题https://stackoverflow.com/questions/20424279/canvas-todataurl-securityerror
    image.setAttribute("crossOrigin", "Anonymous");
    image.src = url;
    // 图片加载失败的错误处理
    image.onerror = () => {
      reject(new Error("urlToBase64 error"));
    };
  });
};

export const base64ToUrl = (base64: string) => {
  // 创建一个 Blob 对象，将 Base64 数据转换为二进制数据
  const binaryData = atob(base64);
  const arrayBuffer = new ArrayBuffer(binaryData.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  for (let i = 0; i < binaryData.length; i++) {
    uint8Array[i] = binaryData.charCodeAt(i);
  }

  const blob = new Blob([uint8Array], { type: "image/png" }); // 替换 'image/png' 为实际图片类型

  // 生成一个可下载的链接
  const url = URL.createObjectURL(blob);
  return url;
};

export const base64ToBlob = (base64: string, mimeType = "image/png") => {
  // Remove the data URL prefix (e.g., 'data:image/png;base64,')
  const base64Data = base64.replace(/^data:[^;]+;base64,/, "");

  // Convert the Base64 string to binary data
  const binaryData = atob(base64Data);

  // Create an array to hold the binary data
  const dataArray = new Uint8Array(binaryData.length);
  for (let i = 0; i < binaryData.length; i++) {
    dataArray[i] = binaryData.charCodeAt(i);
  }

  // Create a Blob with the binary data and specified MIME type
  return new Blob([dataArray], { type: mimeType });
};

export const urlToBlob = async (url: string, mimeType = "image/png") => {
  const base64 = (await urlToBase64(url)) as string;
  const blob = base64ToBlob(base64);
  return blob;
};

export const getImageSize = (url: string) => {
  // 创建实例对象
  return new Promise<Size>((resolve, reject) => {
    var img = new Image();
    // 图片地址
    img.src = url;
    img.onload = () => {
      resolve({
        width: img.width,
        height: img.height,
      });
    };
  });
};

export function getFileExtension(filename: string) {
  return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
}

export const proxyTemplateUrl = (url: string) => {
  const prefixUrl = "/templateApi";
  const urlArr = url.split("/templates");

  if (import.meta.env.MODE === "dev") {
    const a = "http://localhost:8000" + prefixUrl + urlArr[1];
    return a;
  }

  return url;
};

export const hexToCmyk = (hex: string, alpha = 1) => {
  let cmyk = [];
  // 利用正则表达式去掉16进制颜色代码前面的#号
  hex = hex.replace(/^#/, "");
  // 将16进制颜色代码转换为RGB格式
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  cmyk = [0, r, g, b, alpha];
  // 返回RGB格式的颜色值
  return cmyk;
};

// 获取滤镜value
export const getFilterValue = (
  colorValues: string,
  isReverseColor: boolean,
) => {
  if (!colorValues || !isReverseColor) {
    return ``;
  }

  const data = hexToCmyk(colorValues);
  const RGB = data?.slice(1, 4);
  const red = Number((RGB[0] / 255).toFixed(10));
  const green = Number((RGB[1] / 255).toFixed(10));
  const blue = Number((RGB[2] / 255).toFixed(10));
  const transparency = Number(data[4]) || 1;
  const values = `
      0 0 0 ${red} 0
      0 0 0 ${green} 0
      0 0 0 ${blue} 0
      0 0 0 ${transparency} 0
    `;
  return values;
};

export const clipImage = (() => {
  const gct = document.createElement("canvas").getContext("2d")!;

  return async function (
    source: CanvasImageSource,
    clipRect: DOMRect,
    drawRect = new DOMRect(0, 0, clipRect.width, clipRect.height),
  ) {
    gct.canvas.width = drawRect.width;
    gct.canvas.height = drawRect.height;
    gct.clearRect(0, 0, gct.canvas.width, gct.canvas.height);
    gct.drawImage(
      source,
      clipRect.x,
      clipRect.y,
      clipRect.width,
      clipRect.height,
      0,
      0,
      drawRect.width,
      drawRect.height,
    );

    const blob = await new Promise<Blob | null>((res) =>
      gct.canvas.toBlob(res),
    );
    return blob === null ? "" : URL.createObjectURL(blob);
  };
})();

export const createImageFromSprite = (() => {
  const imageMap = new Map();

  return function (
    sprite: HTMLImageElement,
    clipRect: DOMRect,
    { isCache = false, ...props } = {},
  ) {
    const originalVnode = h("img", {
      ...props,
      onVnodeMounted(vnode: VNode) {
        if (isCache && imageMap.has(originalVnode)) {
          vnode.el!.src = imageMap.get(originalVnode);
          return;
        }

        if (sprite.complete) {
          clipImage(sprite, clipRect).then((src) => {
            vnode.el!.src = src;
            isCache && imageMap.set(originalVnode, src);
          });
        } else {
          sprite.addEventListener("load", async () => {
            vnode.el!.src = await clipImage(sprite, clipRect);
            isCache && imageMap.set(originalVnode, vnode.el!.src);
          });
        }
      },

      onVnodeUnmounted(vnode) {
        !isCache && URL.revokeObjectURL(vnode.el!.src);
      },
    });

    return {
      vnode: originalVnode,
      revoke() {
        URL.revokeObjectURL(imageMap.get(originalVnode));
        imageMap.delete(originalVnode);
      },
    };
  };
})();

export const dataURLtoBlob = (dataUrl: string) => {
  var arr = dataUrl.split(","),
    mime = arr[0] && arr[0].match(/:(.*?);/)![1],
    bstr = window.atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

export const blobToFile = (theBlob: any, fileName: string) => {
  theBlob.lastModifiedDate = new Date();
  theBlob.name = fileName;
  return theBlob;
};

export const getSuffix = (name: string) => {
  const nameArr = name?.split(".");
  if (nameArr[nameArr.length - 1]) {
    return "." + nameArr[nameArr.length - 1];
  }
  return ".png";
};

export const getImageType = (type: string) => {
  const typeArr = type?.split("/");
  if (typeArr[1]) {
    return typeArr[0];
  }
  return "image";
};

export const formatDate = (timestamp: number) => {
  let day = "";
  const today = dayjs();
  const yesterday = dayjs().subtract(1, "day");
  const date = dayjs(timestamp);
  if (dayjs(timestamp).isSame(today, "day")) {
    day = "Today";
  } else if (dayjs(timestamp).isSame(yesterday, "day")) {
    day = "Yesterday";
  } else {
    day = date.format("MM/DD/YYYY");
  }

  return `${day} ${date.format(`HH:mm`)}`;
};

export function deepEqual(o1: any, o2: any) {
  if (o1 === o2) {
    return true;
  }
  if (
    typeof o1 === "object" &&
    o1 !== null &&
    typeof o2 === "object" &&
    o2 !== null
  ) {
    const keys1 = Object.keys(o1);
    const keys2 = Object.keys(o2);

    if (keys1.length !== keys2.length) {
      return false;
    }
    for (let key of keys1) {
      if (!deepEqual(o1[key], o2[key])) {
        return false;
      }
    }
    return true;
  }
  return false;
}

export function debounce(
  fn: (...args: any[]) => unknown,
  delay: number,
): typeof fn {
  let timerId: ReturnType<typeof setTimeout> | null;
  return function (...args: any[]) {
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      fn(...args);
      timerId = null;
    }, delay);
  };
}

export function throttled(fn: (...args: any[]) => unknown, delay: number) {
  let timer =  null;
  let starttime = Date.now();
  let isFirstTrigger = true; // 标志位，判断是否是第一次触发
  return function () {
    let curTime = Date.now(); // 当前时间
    let remaining = delay - (curTime - starttime);  // 从上一次到现在，还剩下多少多余时间
    let context = this;
    let args = arguments
    clearTimeout(timer);

    if (isFirstTrigger) {
      fn.apply(context, args);
      isFirstTrigger = false; // 将标志位设为false，表示已经触发过一次
      starttime = Date.now();
      return ;
    }

    if (remaining <= 0) {
      fn.apply(context, args);
      starttime = Date.now();
    }
  }
}

export function useRetryAsync (asyncFunc: any, maxRetries = 3, delay = 3000) {
  const wait = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  return async (...args: any[]) => {
    let retries = 0;
    while (retries < maxRetries) {
      try {
        const result = await asyncFunc(...args);
        return result; // 返回异步函数的结果
      } catch (error) {
        retries++;
        console.log(error);
        console.error(`Attempt ${retries} failed: ${error}`);
        await wait(delay);
      }
    }
    throw new Error(`Exceeded maximum number of retries (${maxRetries})`);
  }
}

export function removeEscapeHtml (str: string) {
  if(!str) {
    return "";
  }
  const res = str;
  // const reg = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F900}-\u{1F9FF}\u{1F1E0}-\u{1F1FF}\u{1F910}-\u{1F93A}\u{1F93C}-\u{1F93E}\u{1F940}-\u{1F945}\u{1F947}-\u{1F94C}\u{1F950}-\u{1F96B}\u{1F980}-\u{1F997}\u{1F9C0}\u{1F9D0}-\u{1F9E6}\u{1F9F0}-\u{1F9FF}\u{1FA70}-\u{1FA73}\u{1FA78}-\u{1FA7A}\u{1FA80}-\u{1FA82}\u{1FA90}-\u{1FA95}\u{1FAB0}-\u{1FAB6}\u{1FAC0}-\u{1FAC2}\u{1FAD0}-\u{1FAD6}\u{200D}-\u{FE0F}\u{20E3}-\u{FE0F}]/gu;
  const emojiReg = emojiRegex();
  const chaReg = /[<>&]/g;
  return res.replace(emojiReg, "").replaceAll(chaReg, "");
}

export const isOnTop = (el: HTMLElement) => {
  if (!el) return false;
  const { left, top, width, height } = el.getBoundingClientRect();
  const centerX = left + width / 2;
  const centerY = top + height / 2;

  const topElement = document.elementFromPoint(centerX, centerY);
  console.log(topElement, el);
  return el === topElement || el.contains(topElement);
};

export function getVideoDuration(file) {
  return new Promise((resolve, reject) => {
      const videoElement = document.createElement('video');
      const url = URL.createObjectURL(file);

      videoElement.src = url;

      videoElement.addEventListener('loadedmetadata', function() {
          const duration = videoElement.duration;
          URL.revokeObjectURL(url);
          resolve(duration);
      });

      videoElement.addEventListener('error', function() {
          URL.revokeObjectURL(url);
          reject(new Error('无法加载视频元数据'));
      });
  });
}