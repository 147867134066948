<template>
  <p class="deal" v-if="!outdated">
    {{"🎉 "}}{{ $t('bvPricing.banner.saveDeal') }}
    <span>YEARSAVE</span>
    <br>
    {{"⚡️ "}}{{ $t('bvPricing.banner.hurryDeal') }}{{ " - " }}
    <div class="countdown">
      {{ countdown }}
    </div>
  </p>
</template>

<script setup>
const targetTime = new Date('2025-01-01T04:59:59');
const countdown = ref("");
const outdated = ref(false);
const timer = ref(null);

const updateCountdown = () => {
  const now = new Date();
  const timeDifference = targetTime - now;

  if (timeDifference <= 0) {
    outdated.value = true;
    clearInterval(timer.value);
    timer.value = null;
    return;
  }

  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
  const seconds = Math.floor((timeDifference / 1000) % 60);

  const daysLabel = days > 1? "days": "day";
  countdown.value = `${days.toString()} ${daysLabel}: ${hours.toString()}: ${minutes.toString().padStart(2, '0')}: ${seconds.toString().padStart(2, '0')}`;
};

onMounted(() => {
  updateCountdown();
  timer.value = setInterval(updateCountdown, 1000);
});
onBeforeUnmount(() => {
  if(timer.value) {
    clearInterval(timer.value);
    timer.value = null;
  }
})
</script>

<style lang="scss" scoped>
.deal {
  padding: .5rem 1rem;
  border-radius: 1.5rem;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.875rem;
  margin-bottom: 1rem;
  color: #FFFFFF;
  text-align: center;
  background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
  background-size: 400% 400%;
  animation: animatedgradient 10s ease infinite alternate;
  transition: all .2s;

  & > span {
    text-decoration: underline;
  }

  & > .countdown {
    display: inline-block;
  }
}

@keyframes animatedgradient {
  0% {
    background-position: 0 50%;
  }

  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0 50%;
  }
}
</style>