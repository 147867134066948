<template>
  <div :class="computedClassName">
    <el-button
      v-bind="
        mergeProps(
          elButtonAttrs,
          { style: { padding, color: fontColor } },
          elButtonProps,
        )
      "
    >
      <slot name="preIcon" v-if="$slots.preIcon"></slot>
      <slot v-if="$slots.loading" name="loading"></slot>
      <template v-if="loading">
        <!-- <el-icon v-else :class="ns.is('loading')">
          <component :is="loadingIcon" />
        </el-icon> -->
      </template>
      <el-icon v-else-if="icon || $slots.icon">
        <component :is="icon" v-if="icon" />
        <slot v-else name="icon"></slot>
      </el-icon>
      <span v-if="$slots.default" class="flex items-center">
        <slot></slot>
      </span>
      <slot name="suffixIcon" v-if="$slots.suffixIcon"></slot>
    </el-button>
  </div>
</template>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<script setup lang="ts">
import { computed, mergeProps, useAttrs } from "vue";
import { buttonProps, useNamespace } from "element-plus";
import { concatClassNames } from "@/utils/dom";
import { createGroupProxy } from "@/utils/type";
import type { ButtonType } from "../type";
import type { PropType } from "vue";

const props = defineProps({
  ...buttonProps,
  padding: String,
  fontColor: String,
  width: String,
  round: {
    type: Boolean,
    default: true,
  },
  size: {
    type: String as PropType<ButtonType>,
    default: "default",
  },
  onclick: Function as PropType<(e: MouseEvent) => void>,
});

const { customAttrs, rest: elButtonAttrs } = createGroupProxy(useAttrs(), {
  customAttrs: ["class"],
});

const { customProps, rest: elButtonProps } = createGroupProxy(props, {
  customProps: ["padding", "fontColor", "size"],
});

const ns = useNamespace("button");
const computedClassName = computed(() => {
  return concatClassNames(
    {
      "bv-mini-button": props.size === "mini",
      "bv-extra-small-button": props.size === "extra-small",
      "bv-small-button": props.size === "small",
      "bv-middle-button": props.size === "default",
      "bv-large-button": props.size === "large",
      "bv-more-large-button": props.size === "more-large",
    },
    customAttrs.class as string,
  );
});
</script>
<style lang="scss" scoped>
:deep(.el-button.is-round) {
  border-radius: 9999px !important;
}

.bv-mini-button {
  :deep(.el-button) {
    height: auto;
    padding: 4px 15px;
    font-size: 14px;
    font-weight: 400;
  }
}

.bv-extra-small-button {
  :deep(.el-button) {
    height: 36px;
    padding: 0 36px;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
  }
}

.bv-small-button {
  :deep(.el-button) {
    height: 42px;
    font-size: 14px;
    padding: 0 36px;
    font-weight: 400;
    display: flex;
    align-items: center;
  }
}

.bv-middle-button {
  :deep(.el-button) {
    height: 54px;
    padding: 0 36px;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    align-items: center;
  }
}

.bv-large-button {
  :deep(.el-button) {
    height: 64px;
    padding: 0 36px;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    align-items: center;
  }
}

.bv-more-large-button {
  :deep(.el-button) {
    height: auto;
    padding: 15px 40px 15px 51px;
  }
}

.el-button {
  --bv-button-color: #060606;
  --bv-button-border-color: #bbbfc4;
  --el-button-text-color: var(--bv-button-color);
  --el-button-bg-color: #ffffff;
  --el-button-border-color: var(--bv-button-border-color);
  --el-button-hover-text-color: var(--bv-button-color);
  --el-button-hover-bg-color: #f5f6f7;
  --el-button-hover-border-color: var(--bv-button-border-color);
  --el-button-active-text-color: var(--bv-button-color);
  --el-button-active-bg-color: #f5f6f7;
  --el-button-active-border-color: var(--bv-button-border-color);
  --el-button-font-weight: 400;

  outline: none;
}
</style>
