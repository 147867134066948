import dayjs from "dayjs";

export function dateToHms(date) {
  return dayjs(date).format("HH:mm:ss");
}

export function secondsToHms(sec) {
  sec = Math.ceil(sec);

  let hours = Math.floor(sec / 3600);
  let minutes = Math.floor((sec - hours * 3600) / 60);
  let seconds = sec - hours * 3600 - minutes * 60;

  hours = hours.toString().padStart(2, "0");
  minutes = minutes.toString().padStart(2, "0");
  seconds = seconds.toString().padStart(2, "0");

  if (hours === "00") {
    return minutes + ":" + seconds;
  }
  return hours + ":" + minutes + ":" + seconds;
}

export function frameToAxis(frame, range) {
  if (frame % 30 === 0) {
    return frameToHms(frame);
  } else {
    return (frame % 30) + "f";
  }
}

export function frameToHms(frame) {
  const sec = frame / 30;

  let minutes = Math.floor(sec / 60);
  let seconds = Math.floor(sec - minutes * 60);

  minutes = minutes.toString().padStart(2, "0");
  seconds = seconds.toString().padStart(2, "0");

  return minutes + ":" + seconds;
}

export function frameToHmss(frame) {
  const fps = 30;
  const sec = frame / fps;

  let minutes = Math.floor(sec / 60);
  let seconds = Math.floor(sec - minutes * 60);
  let frames = frame - minutes * 60 * fps - seconds * fps;

  minutes = minutes.toString().padStart(2, "0");
  seconds = seconds.toString().padStart(2, "0");
  frames = frames.toString().padStart(2, "0");

  return minutes + ":" + seconds + ":" + frames;
}

export function getComputedSize(element) {
  const style = getComputedStyle(element);
  const width = style.getPropertyValue("width");
  const height = style.getPropertyValue("height");

  return [parseFloat(width), parseFloat(height)];
}

export function getBounds(element) {
  const style = getComputedStyle(element);
  const left = style.getPropertyValue("left");
  const top = style.getPropertyValue("top");

  return [parseFloat(left), parseFloat(top)];
}

export function getTranslate(element) {
  const style = getComputedStyle(element);
  const transform = style.getPropertyValue("transform");
  const matrix = new DOMMatrix(transform);

  return [matrix.m41, matrix.m42];
}

export function getInnerBounds(parent, element) {
  const parentBox = parent.getBoundingClientRect();
  const elementBox = element.getBoundingClientRect();
  const left = elementBox.left - parentBox.left;
  const top = elementBox.top - parentBox.top;

  return [left, top];
}

export function clamp(a, min, max) {
  return Math.max(min, Math.min(max, a));
}

export function courtship(num) {
  return num & ~1;
}

export function sleep(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}
