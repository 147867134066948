import Cor from "@boolv/creator";
import { axisScaling } from "../utils/resize";
import { useTrackStore } from "@/store/modules/track";
import { courtship } from "../utils";

const ratioMap = new Map([
  ["9:16", [480, 854]],
  ["16:9", [854, 480]],
  ["1:1", [480, 480]],
  ["4:5", [480, 600]],
  ["2:3", [480, 720]],
]);

let baseRect = ratioMap.get("9:16");
const resizeType = new Set([
  "image",
  "video",
  "sticker",
  "text",
  "graphic",
  "effect",
]);
const resources = reactive(new Map());
const timeline = reactive({
  mode: "select",
  autoFit: true,
  baseline: 0.05,
  frameWidth: 3,
  scale: 60,
  maxScale: 300,
  minScale: 1,
});

const creator = shallowRef(null);
const dragData = reactive({
  target: null,
  segment: null,
  data: null,
  file: null,
  canvasMouseUp: false,
});
const replaceData = reactive({
  node: null,
  newData: null,
  src: null,
  poster: null,
  showVideoCliper: false,
  showBack: false,
  transparent: false,
  duration: 0,
  apply: null,
});
const widgetMenu = reactive({ visible: false, left: 0, top: 0 });
const segmentMenu = reactive({ visible: false, left: 0, top: 0, opacity: 0 });
const canvasSelected = ref(false);
const spliting = ref(false);
const animations = ref([]);
const showTransitionAdder = ref(false);
const showPopover = ref(false);
const showCropper = ref(false);
const showDiscardCoverDialog = ref(false);
const showCoverDialog = ref(false);
const showExportDialog = ref(false);
const showMaterialDialog = ref(false);
const dialogCallback = ref(() => {});
const clickDialogFrom = ref("replace");
const guideStep = ref(1);
const magnet = ref(true);
const autoSnap = ref(true);
const previewAxis = ref(false);
const timelineEnterd = ref(false);
const magnetMap = reactive(new Map());
const showMaterial = ref(false);
const materialTab = ref("media");
const mediaTab = ref("project");
const primaryNode = ref(null);
const nodeMap = reactive(new Map());
const nodeSet = reactive(new Set());
const activeNodeMap = reactive(new Map());
const attrTabMap = reactive({});
const videoSpritesheetMap = reactive({});
const updateProject = ref(0);
const updateCloud = ref(0);
const displayFrame = ref(0);
const currentFrame = ref(0);
const totalFrame = ref(0);
const playing = ref(false);
const coverPreviewer = ref(null);
const videoCover = ref(null);
const defaultCover = ref(null);
const tracks = ref([]);
const zIndexDelta = ref(0);
const voiceoverText = ref(null);
const copyNode = ref(null);
const canPlay = computed(() => totalFrame.value > 0);
const primaryTrack = computed(() => tracks.value[0]);
const empty = computed(
  () =>
    tracks.value.length === 0 ||
    (tracks.value.length === 1 && primaryTrack.value.children.length === 0)
);
const currentNode = computed(() => {
  if (activeNodeMap.size === 1) {
    return activeNodeMap.values().next().value;
  }
  return null;
});

watch([showMaterialDialog, () => replaceData.showVideoCliper], ([v1, v2]) => {
  if (!v1 && !v2) {
    replaceData.node = null;
  }
});
watch(currentFrame, (newFrame) => {
  displayFrame.value = newFrame;
});
watch(
  () => timeline.scale,
  (newScale) => {
    timeline.frameWidth = timeline.baseline * newScale;
  }
);
watch(creator, (newCreator) => {
  if (newCreator) {
    videoCover.value = newCreator.videoCover;
    tracks.value = [...newCreator.children];
    resources.clear();
    nodeMap.clear();

    for (const track of tracks.value) {
      for (const node of track.children) {
        const newNode = getNode(node);

        switch (newNode.type) {
          case "image":
          case "video":
          case "audio":
          case "sticker":
            mappingResource(newNode.conf.src, newNode);
            break;
        }
        if (newNode.display) {
          newNode.isVisible = newNode.display.visible;
        }
        // console.log('[]', newNode.id)
        newNode.isPrepared = newNode.prepared;
        newNode.on("visible", (visible) => (newNode.isVisible = visible));
        newNode.on("prepare", (prepare) => (newNode.isPrepared = prepare));
        newNode.on("render", () => newNode.updateId++);
        nodeMap.set(newNode.id, newNode);
      }
    }
  }
});

function start(config, sourceRatio) {
  return new Promise((resolve, reject) => {
    if (creator.value) {
      destroy();
    }
    const cor = new Cor.Creator(config, {
      container: document.getElementById("player"),
      autoResize: false,
    });

    cor.on("durationchange", (frameNum) => (totalFrame.value = frameNum));
    cor.on("timeupdate", (frame) => (currentFrame.value = frame));
    cor.on("ended", () => setPlaying(false));
    cor.on("error", reject);
    cor.on("canplay", () => {
      creator.value = cor;
      baseRect = ratioMap.get(sourceRatio);
      resolve();
    });
    cor.start();
  });
}

function destroy() {
  currentFrame.value = 0;
  totalFrame.value = 0;
  playing.value = false;
  tracks.value = [];
  nodeMap.clear();
  nodeSet.clear();
  activeNodeMap.clear();

  if (creator.value) {
    creator.value.destroy();
  }
  creator.value = null;
}

async function play() {
  if (creator.value) {
    setPlaying(true);

    if (creator.value.ended) {
      await seekTo(0);
    }
    await creator.value.play();
  }
}

async function pause() {
  if (creator.value) {
    setPlaying(false);
    await creator.value.pause();
  }
}

async function seekTo(value) {
  if (creator.value) {
    await creator.value.seekTo(value);
  }
}

function frameToWidth(frame) {
  return frame * timeline.frameWidth;
}

function widthToFrame(width) {
  return Math.round(width / timeline.frameWidth);
}

function secondToFrame(duration) {
  return Math.floor(duration * 30);
}

function getNode(node, deep = true) {
  if (!deep && node?.parent?.type === "scene") {
    return node.parent;
  } else if (deep && node?.type === "scene") {
    return node.children[0];
  } else {
    return node;
  }
}

function createTrack(kind, index = -1) {
  if (kind === "video") kind = "image";
  if (kind === "speech") kind = "audio";

  const track = new Cor.Track({ kind });

  if (index >= 0) {
    creator.value.addChildAt(track, index);
  } else {
    creator.value.addChild(track);
  }
  return track;
}

function getTrack(node, zIndex = 0) {
  const result = { used: false };
  const duration = node.getDuration();
  const shallowNode = getNode(node, false);
  const excludes = [];
  const elements = document.querySelectorAll(".segment.dragging");

  if (
    node.type === "transition" ||
    (magnet.value && ["image", "video"].includes(node.type) && zIndex === 0)
  ) {
    result.track = toRaw(primaryTrack.value);
    result.used = true;
    return result;
  }
  if (shallowNode) {
    excludes.push(shallowNode.id);
  }
  for (const element of elements) {
    if (element.dataset.id === node.id) continue;

    const activeNode = activeNodeMap.get(element.dataset.id);
    const shallowNode = getNode(activeNode, false);

    if (shallowNode) {
      excludes.push(shallowNode.id);
    }
  }
  for (let i = zIndex; i < tracks.value.length; i++) {
    const track = toRaw(tracks.value[i]);

    if (track.valid(node)) {
      track.annotate();

      const { startFrame } = node;
      const closest = track.getClosest(startFrame, excludes);

      if (closest) {
        if (startFrame < closest.startFrame) {
          if (duration > closest.startFrame - startFrame) {
            continue;
          }
        } else if (startFrame >= closest.endFrame) {
          let nextSibling = toRaw(closest.nextSibling);

          while (nextSibling && excludes.includes(nextSibling.id)) {
            nextSibling = nextSibling.nextSibling;
          }
          if (nextSibling) {
            if (duration > nextSibling.startFrame - startFrame) {
              continue;
            }
          }
        } else {
          continue;
        }
      }

      result.used = true;
      result.track = track;
      break;
    }
  }
  if (!result.used) {
    result.track = createTrack(node.type);
  }
  return result;
}

function mappingResource(key, value) {
  const resource = resources.get(key);

  if (!resource) {
    resources.set(key, [value]);
  } else {
    resource.push(value);
  }
}

async function addNode(conf = {}, options = {}) {
  activeNodeMap.clear();

  if (!conf.duration) {
    conf.duration = 90;
  }
  if (magnet.value && ["image", "video"].includes(conf.type)) {
    let prevEnd;
    const track = primaryTrack.value;
    const frame = currentFrame.value;

    options.shouldAnnotate = true;
    options.shouldRefresh = true;

    for (const node of track.children) {
      const duration = node.getDuration();
      const center = node.startFrame + duration / 2;

      if (node.type === "transition") {
        if (prevEnd) {
          node.conf.start = prevEnd - duration / 2;
          node.conf.end = prevEnd + duration / 2;
        }
        continue;
      }
      if (prevEnd) {
        const newNode = getNode(node);

        newNode.conf.start = prevEnd;
        newNode.conf.end = prevEnd + duration;
        prevEnd = prevEnd + duration;
        continue;
      }
      if (node.startFrame <= frame && frame < center) {
        const newStart = node.startFrame + conf.duration;
        const newNode = getNode(node);

        conf.index = track.getChildIndex(node);
        conf.start = node.startFrame;
        conf.end = conf.start + conf.duration;
        newNode.conf.start = newStart;
        newNode.conf.end = newStart + duration;
        prevEnd = newStart + duration;
      } else if (center <= frame && frame < node.endFrame) {
        conf.index = track.getChildIndex(node) + 1;
        conf.start = node.endFrame;
        conf.end = conf.start + conf.duration;
        prevEnd = conf.start + conf.duration;
      }
    }
  }
  const {
    type,
    start = currentFrame.value,
    duration,
    index = -1,
    zIndex,
    ...restConf
  } = conf;
  const end = conf.end || start + duration;
  const Node = Cor.NODE_MAP[type];
  const node = new Node({ ...restConf, start, end });
  const { track, used } = getTrack(node, zIndex);

  if (track.kind === "primary") {
    if (["image", "video"].includes(node.type)) {
      const scene = new Cor.Scene();

      if (index >= 0) {
        track.addChildAt(scene, index);
      } else {
        track.addChild(scene);
      }
      scene.addChild(node);
      triggerRef(creator);
      await scene.start();
    } else {
      track.addChildAt(node, index);
      triggerRef(creator);
      await node.start();
    }
  } else {
    track.addChild(node);
    triggerRef(creator);
    await (used ? node : track).start();
  }
  if (options.shouldAnnotate || end > totalFrame.value || !used) {
    creator.value.annotate();
  }
  if (options.shouldRefresh) {
    creator.value.refresh();
  } else if (!options.skipRender) {
    creator.value.render();
  }
  nodeSet.add(node);
  setActiveNode(node);

  const trackHelper = () => {
    const { collectData, track, clearEventData } = useTrackStore();
    const id = node.conf.sourceId || node.id;
    collectData("boolvideo_timeline_edit_click", {
      click: `add_${node.type}`,
      element_type: node.type,
      element_id: id,
    });
    track("boolvideo_timeline_edit_click");
    clearEventData("boolvideo_timeline_edit_click");
  };
  trackHelper();
}

function getXY() {
  const { width, height } = creator.value.screen;
  return { x: width / 2, y: height / 2 };
}

async function addTransitionNode(conf, frame = currentFrame.value, options) {
  const track = primaryTrack.value;
  let current, sibling;

  for (const node of track.children) {
    if (node.type === "transition") continue;
    if (node.startFrame > frame) break;
    if (node.startFrame <= frame && frame <= node.endFrame) {
      const duration = node.getDuration();
      const prevSibling = node.prevSibling;
      const nextSibling = node.nextSibling;
      const isValidPrev =
        prevSibling &&
        (prevSibling.type === "transition" ||
          prevSibling.endFrame === node.startFrame);
      const isValidNext =
        nextSibling &&
        (nextSibling.type === "transition" ||
          nextSibling.startFrame === node.endFrame);

      current = node;

      if (frame <= node.startFrame + duration / 2) {
        if (isValidPrev) {
          sibling = prevSibling;
        } else if (isValidNext) {
          sibling = nextSibling;
        }
      } else {
        if (isValidNext) {
          sibling = nextSibling;
        } else if (isValidPrev) {
          sibling = prevSibling;
        }
      }
      break;
    }
  }
  if (current && sibling) {
    const currentIndex = track.getChildIndex(current);

    if (sibling.type === "transition") {
      const index = track.getChildIndex(sibling);
      const { start, end } = sibling.conf;
      removeNode(sibling, { skipTrigger: true, skipRefresh: true });
      await addNode(
        { type: "transition", ...conf, start, end, index },
        options
      );
    } else {
      const minDuration = Math.min(
        current.getDuration(),
        sibling.getDuration(),
        60
      );

      if (minDuration < 4) {
        return null;
      } else {
        const siblingIndex = track.getChildIndex(sibling);
        const duration = courtship(minDuration / 2);
        const start =
          (currentIndex > siblingIndex
            ? current.startFrame
            : current.endFrame) -
          duration / 2;
        const end = start + duration;
        const index =
          currentIndex > siblingIndex ? siblingIndex + 1 : siblingIndex;
        await addNode(
          { type: "transition", ...conf, start, end, index },
          options
        );
      }
    }
  }
  return sibling;
}

function splitNode(frame = currentFrame.value, node) {
  if (spliting.value) {
    return;
  }
  spliting.value = true;

  const children = node
    ? [node]
    : activeNodeMap.size > 0
    ? activeNodeMap.values()
    : primaryTrack.value.children;

  for (const child of children) {
    const node = getNode(child);
    const rawNode = toRaw(node);

    if (rawNode.type === "transition") {
      continue;
    }
    if (rawNode.startFrame < frame && frame < rawNode.endFrame) {
      const { animation, keyframes } = node.conf;
      const parent = toRaw(rawNode.parent);
      const duration = frame - rawNode.startFrame;
      const cloneNode = rawNode.clone();

      if (["video", "audio", "speech"].includes(cloneNode.type)) {
        const ss = rawNode.getSs();
        const newSs = ss + duration;

        cloneNode.conf.ss = newSs;
        cloneNode.setSs(newSs);
      }
      cloneNode.conf.start = frame;
      cloneNode.conf.end = rawNode.endFrame;
      cloneNode.setDuration(frame, rawNode.endFrame);

      if (animation) {
        const newAnimation = {};
        const cloneAnimation = {};
        const animIn = animation.in;
        const animOut = animation.out;

        if (animIn) {
          const newAnim = { ...animIn };
          let cloneAnim = { ...animIn };

          if (animIn.end > frame) {
            cloneAnim.start = frame;
            newAnim.end = frame;
          } else {
            cloneAnim = null;
          }
          newAnimation.in = newAnim;
          cloneAnimation.in = cloneAnim;
        }
        if (animOut) {
          let newAnim = { ...animOut };
          const cloneAnim = { ...animOut };

          if (animOut.start < frame) {
            cloneAnim.start = frame;
            newAnim.end = frame;
          } else {
            newAnim = null;
          }
          newAnimation.out = newAnim;
          cloneAnimation.out = cloneAnim;
        }
        node.conf.animation = newAnimation;
        cloneNode.conf.animation = cloneAnimation;
        cloneNode.setAnimation(cloneAnimation);
      }
      if (keyframes) {
        const newKeyframes = {};
        const cloneKeyframes = {};
        let needComplement = false;

        for (const frameString of Object.keys(keyframes)) {
          if (parseInt(frameString) < frame) {
            newKeyframes[frameString] = keyframes[frameString];
          } else {
            needComplement = true;
            break;
          }
        }
        if (needComplement) {
          const attr = node.keyframes.getAttr(frame);

          newKeyframes[frame.toString()] = attr;
          cloneKeyframes[frame.toString()] = attr;
        }
        for (const frameString of Object.keys(keyframes)) {
          if (parseInt(frameString) > frame) {
            cloneKeyframes[frameString] = keyframes[frameString];
          }
        }
        node.conf.keyframes = newKeyframes;
        cloneNode.conf.keyframes = cloneKeyframes;
        cloneNode.setKeyframes(cloneKeyframes);
      }
      if (parent.type === "scene") {
        const track = parent.parent;
        const index = track.getChildIndex(parent);
        const scene = parent.clone();
        track.addChildAt(scene, index + 1);
        scene.addChild(cloneNode);
        scene.start(false).then(refresh);
      } else {
        const index = parent.getChildIndex(rawNode);
        parent.addChildAt(cloneNode, index + 1);
        cloneNode.start(false).then(refresh);
      }
      node.conf.end = frame;
    }
  }
  triggerRef(creator);
  nextTick(() => (spliting.value = false));
}

function deleteLeft() {
  if (spliting.value) {
    return;
  }
  spliting.value = true;

  const children =
    activeNodeMap.size > 0
      ? activeNodeMap.values()
      : primaryTrack.value.children;
  const splitNodes = [];

  for (const child of children) {
    const node = getNode(child);
    const frame = currentFrame.value;

    if (node.type === "transition") {
      continue;
    }
    if (node.startFrame < frame && frame < node.endFrame) {
      const duration = frame - node.startFrame;
      const { animation, keyframes, mask } = node.conf;

      if (["video", "audio", "speech"].includes(node.type)) {
        const ss = node.getSs();
        const newSs = ss + duration;
        node.conf.ss = newSs;
      }
      if (animation) {
        const newAnimation = {};
        const animIn = animation.in;
        const animOut = animation.out;

        if (animIn) {
          let anim = { ...animIn };

          if (animIn.end >= frame) {
            anim.start = frame;
          } else {
            anim = null;
          }
          newAnimation.in = anim;
        }
        if (animOut) {
          let anim = { ...animOut };

          if (animOut.start < frame) {
            anim.start = frame;
          }
          newAnimation.out = anim;
        }
        node.conf.animation = newAnimation;
      }
      if (keyframes) {
        const newKeyframes = {};
        const needComplement = Object.keys(keyframes).some(
          (i) => parseInt(i) < frame
        );
        if (needComplement) {
          const attr = node.keyframes.getAttr(frame);
          newKeyframes[frame.toString()] = attr;
        }
        for (const frameString of Object.keys(keyframes)) {
          const frameNum = parseInt(frameString);

          if (frame <= frameNum && frameNum <= node.endFrame) {
            newKeyframes[frameString] = keyframes[frameString];
          }
        }
        node.conf.keyframes = newKeyframes;
      }
      if (mask && mask.keyframes) {
        const newKeyframes = {};
        const keyframes = mask.keyframes;
        const needComplement = Object.keys(keyframes).some(
          (i) => parseInt(i) < frame
        );
        if (needComplement) {
          const attr = node.mask.keyframes.getAttr(frame);
          newKeyframes[frame.toString()] = attr;
        }
        for (const frameString of Object.keys(keyframes)) {
          const frameNum = parseInt(frameString);

          if (frame <= frameNum && frameNum <= node.endFrame) {
            newKeyframes[frameString] = keyframes[frameString];
          }
        }
        node.conf.mask = { ...mask, keyframes: newKeyframes };
      }
      node.conf.start = frame;
      splitNodes.push(node);
    }
  }
  if (magnet.value) {
    adsorb();
    nextTick(refresh);
  }
  let min = Infinity;
  for (const node of splitNodes) {
    min = Math.min(min, node.conf.start);
  }
  seekTo(min);
  nextTick(() => (spliting.value = false));
}

function deleteRight() {
  if (spliting.value) {
    return;
  }
  spliting.value = true;

  const children =
    activeNodeMap.size > 0
      ? activeNodeMap.values()
      : primaryTrack.value.children;
  const splitNodes = [];

  for (const child of children) {
    const node = getNode(child);
    const frame = currentFrame.value;
    const { animation, keyframes, mask } = node.conf;

    if (node.type === "transition") {
      continue;
    }
    if (node.startFrame < frame && frame < node.endFrame) {
      if (animation) {
        const newAnimation = {};
        const animIn = animation.in;
        const animOut = animation.out;

        if (animIn) {
          const anim = { ...animIn };

          if (animIn.end >= frame) {
            anim.end = frame;
          }
          newAnimation.in = anim;
        }
        if (animOut) {
          const anim = { ...animOut };

          if (animOut.start < frame) {
            anim.end = frame;
          }
          newAnimation.out = anim;
        }
        node.conf.animation = newAnimation;
      }
      if (keyframes) {
        const newKeyframes = {};
        const needComplement = Object.keys(keyframes).some(
          (i) => parseInt(i) > frame
        );
        if (needComplement) {
          const attr = node.keyframes.getAttr(frame);
          newKeyframes[frame.toString()] = attr;
        }
        for (const frameString of Object.keys(keyframes)) {
          const frameNum = parseInt(frameString);

          if (node.startFrame <= frameNum && frameNum <= frame) {
            newKeyframes[frameString] = keyframes[frameString];
          }
        }
        node.conf.keyframes = newKeyframes;
      }
      if (mask && mask.keyframes) {
        const newKeyframes = {};
        const keyframes = mask.keyframes;
        const needComplement = Object.keys(keyframes).some(
          (i) => parseInt(i) > frame
        );
        if (needComplement) {
          const attr = node.mask.keyframes.getAttr(frame);
          newKeyframes[frame.toString()] = attr;
        }
        for (const frameString of Object.keys(keyframes)) {
          const frameNum = parseInt(frameString);

          if (frame <= frameNum && frameNum <= node.endFrame) {
            newKeyframes[frameString] = keyframes[frameString];
          }
        }
        node.conf.mask = { ...mask, keyframes: newKeyframes };
      }
      node.conf.end = frame;
      splitNodes.push(node);
    }
  }
  if (magnet.value) {
    adsorb();
    nextTick(refresh);
  }
  let min = Infinity;
  for (const node of splitNodes) {
    min = Math.min(min, node.conf.start);
  }
  seekTo(min);
  nextTick(() => (spliting.value = false));
}

function removeNode(node, options = {}) {
  activeNodeMap.delete(node.id);

  const newNode = getNode(node);
  const shallowNode = getNode(node, false);
  const prevSibling = shallowNode.prevSibling;
  const nextSibling = shallowNode.nextSibling;
  const shouldRemoveTransPrev =
    !options.skipRemoveTrans &&
    prevSibling &&
    prevSibling.type === "transition" &&
    !activeNodeMap.has(prevSibling.id);
  const shouldRemoveTransNext =
    !options.skipRemoveTrans &&
    nextSibling &&
    nextSibling.type === "transition" &&
    !activeNodeMap.has(nextSibling.id);
  const parent = newNode.parent;

  newNode.destroy();
  if (shouldRemoveTransPrev) prevSibling.destroy();
  if (shouldRemoveTransNext) nextSibling.destroy();
  if (parent.children.length === 0) parent.destroy();
  if (parent.type === "scene") adsorb();
  if (!options.skipTrigger) triggerRef(creator);
  if (!options.skipRefresh) nextTick(refresh);
}

async function replaceNode(node, file) {
  let newNode;

  const crop = node.conf.crop;
  const oldConf = {
    start: node.conf.start,
    end: node.conf.end,
    x: node.conf.x,
    y: node.conf.y,
    rotate: node.conf.rotate,
    scale: node.conf.scale,
    fit: node.conf.fit,
    flip: node.conf.flip,
    opacity: node.conf.opacity,
    blend: node.conf.blend,
    animation: node.conf.animation,
    keyframes: node.conf.keyframes,
    mask: node.conf.mask,
  };
  replaceData.newData = {
    src: file.previewUrl,
    hdUrl: file.preview1080Url,
    coverPic: file.coverPic,
    materialMeta: {
      width480: file.width480,
      width1080: file.width1080,
      url1080: file.preview1080Url,
    },
  };
  if (!oldConf.fit || oldConf.fit === "none") {
    const [w, h] = node.getWH();
    const sw = creator.value.screen.width;
    const sh = creator.value.screen.height;
    const dw = file.width;
    const dh = file.height;
    let newScale;

    if (w > h) {
      if (dw > dh) {
        newScale = w / dw;
      } else {
        newScale = (sh * w) / sw / dh;
      }
    } else {
      if (dw > dh) {
        newScale = (sw * h) / sh / dw;
      } else {
        newScale = h / dh;
      }
    }
    if (file.tag === "logo") {
      newScale = 0.5;
    }
    replaceData.newData.scale = newScale;
  }
  if (crop) {
    const baseTexture = node.getBaseTexture();
    const tw = baseTexture.width;
    const th = baseTexture.height;
    const rotate = crop.rotate;
    const sinA = Math.abs(Math.sin(rotate));
    const cosA = Math.abs(Math.cos(rotate));
    const rotatedWidth = tw * cosA + th * sinA;
    const rotatedHeight = tw * sinA + th * cosA;
    const scaleWidth = rotatedWidth / tw;
    const scaleHeight = rotatedHeight / th;
    const scale = Math.max(scaleWidth, scaleHeight);
    const scaledWidth = tw * scale;
    const scaledHeight = th * scale;

    const x = file.width * (crop.x / scaledWidth);
    const y = file.height * (crop.y / scaledHeight);
    const width = file.width * (crop.width / scaledWidth);
    const height = file.height * (crop.height / scaledHeight);

    replaceData.newData.crop = { x, y, width, height, rotate };
  }
  if (file.type === "image") {
    if (node.type === "image") {
      Object.assign(node.conf, replaceData.newData);
    } else {
      newNode = new Cor.Image({ ...oldConf, ...replaceData.newData });
    }
  } else {
    const oldDuration = node.getDuration();
    const newDuration = secondToFrame(file.duration);
    const transparent = file.aiType === "videoBgRemove";

    replaceData.newData.transparent = transparent;

    if (newDuration > oldDuration) {
      const getSs = new Promise((resolve) => (replaceData.apply = resolve));
      replaceData.src = file.previewUrl;
      replaceData.poster = file.coverPic;
      replaceData.duration = oldDuration;
      replaceData.transparent = transparent;
      replaceData.showVideoCliper = true;

      const ss = await getSs;

      if (ss < 0) {
        if (ss === -1) {
          clickDialogFrom.value = "replace";
          showMaterialDialog.value = true;
        }
        return false;
      }
      replaceData.newData.ss = ss;
    } else {
      const speed = Math.max(
        0.1,
        Math.floor((newDuration / oldDuration) * 10) / 10
      );
      const newEnd = node.conf.start + newDuration / speed;
      replaceData.newData.ss = 0;
      replaceData.newData.speed = speed;
      replaceData.newData.end = newEnd < node.conf.end ? newEnd : node.conf.end;
    }
    if (node.type === "image") {
      newNode = new Cor.Video({ ...oldConf, ...replaceData.newData });
    } else {
      Object.assign(node.conf, replaceData.newData);
    }
  }
  if (newNode) {
    const parent = node.parent;
    parent.addChild(newNode);
    removeNode(node, { skipRemoveTrans: true });
    (parent.type === "scene" ? parent : newNode).start().then(() => {
      triggerRef(creator);
      refresh();
    });
    setActiveNode(newNode);
  }
  return true;
}

function removeActiveNodes() {
  for (const node of activeNodeMap.values()) {
    const shouldSkip = activeNodeMap.size > 1;
    removeNode(node, { skipTrigger: shouldSkip, skipRefresh: shouldSkip });
    const trackHelper = () => {
      const { collectData, track, clearEventData } = useTrackStore();
      const id = node.conf.sourceId || node.id;
      collectData("boolvideo_timeline_edit_click", {
        click: `delete_${node.type}`,
        element_type: node.type,
        element_id: id,
      });
      track("boolvideo_timeline_edit_click");
      clearEventData("boolvideo_timeline_edit_click");
    };
    trackHelper();
  }
}

function refresh() {
  creator.value.annotate();
  creator.value.refresh();
}

function isSticker(node) {
  return (
    node.type === "sticker" ||
    node.conf.tag === "logo" ||
    (node.type === "image" && node.conf.tag === null)
  );
}

function resize(width, height) {
  const { screen } = creator.value;
  const xRatio = width / screen.width;
  const yRatio = height / screen.height;

  activeNodeMap.clear();

  for (const node of nodeMap.values()) {
    if (node.type === "transition") node.resize(width, height);
    if (!resizeType.has(node.type)) continue;

    const initConf = { ...node.conf, scale: node.getScale() };
    const { mask, keyframes } = node.conf;

    node.conf.x *= xRatio;
    node.conf.y *= yRatio;

    resize: if (node.type === "text") {
      node.conf.fontSize *= xRatio;
      node.conf.wordWrapWidth *= xRatio;
    } else if (node.type === "graphic") {
      if (node.conf.shape === "circle") break resize;
      node.conf.width *= xRatio;
      node.conf.height *= yRatio;
    } else if (mask) {
      const originalRect = [mask.width, mask.height];
      mask.x *= xRatio;
      mask.y *= yRatio;
      mask.width *= xRatio;
      mask.height *= yRatio;

      axisScaling.toXY(node, originalRect, [mask.width, mask.height], baseRect);

      if (mask.keyframes !== undefined) {
        const keyframes = { ...mask.keyframes };

        for (const [key, keyframe] of Object.entries(keyframes)) {
          const newKeyframe = { ...keyframe };

          if ("x" in keyframe) newKeyframe.x *= xRatio;
          if ("y" in keyframe) newKeyframe.y *= yRatio;
          if ("width" in keyframe) newKeyframe.width *= xRatio;
          if ("height" in keyframe) newKeyframe.height *= yRatio;

          keyframes[key] = newKeyframe;
        }

        mask.keyframes = keyframes;
      }
    } else {
      const originalRect = [screen.width, screen.height];
      const targetRect = [width, height];

      if (isSticker(node)) {
        axisScaling.toXY(node, originalRect, targetRect, baseRect);
      } else {
        axisScaling.toXY(node, originalRect, targetRect, baseRect);
      }
    }

    if (keyframes !== undefined) {
      const newKeyframes = { ...keyframes };

      for (const [key, keyframe] of Object.entries(keyframes)) {
        const newKeyframe = { ...keyframe };

        if ("x" in keyframe) newKeyframe.x *= xRatio;
        if ("y" in keyframe) newKeyframe.y *= yRatio;
        if ("scale" in keyframe)
          newKeyframe.scale *= node.conf.scale / initConf.scale;

        newKeyframes[key] = newKeyframe;
      }

      node.conf.keyframes = newKeyframes;
    }
  }

  creator.value.resize(width, height);
  creator.value.render();
}

function setPlaying(value) {
  playing.value = value;
}

function setActiveNode(node, shouldClear = true) {
  if (shouldClear) {
    activeNodeMap.clear();
  }
  if (!activeNodeMap.has(node.id)) {
    activeNodeMap.set(node.id, node);
  }
}

function adsorb(filtered) {
  const track = primaryTrack.value;
  const children = filtered
    ? track.children.filter((child) => !activeNodeMap.has(getNode(child).id))
    : track.children;

  for (let i = 0; i < children.length; i++) {
    let prevNode = getNode(children[i - 1]);
    const currNode = getNode(children[i]);
    const start = currNode.conf.start;
    const end = currNode.conf.end;
    const duration = end - start;

    if (!prevNode) {
      if (currNode.type === "transition") {
        removeNode(currNode, { skipTrigger: true, skipRefresh: true });
        children[i] = null;
      } else if (start !== 0) {
        currNode.conf.start = 0;
        currNode.conf.end = duration;
        magnetMap.set(currNode.id, 0);
      }
      continue;
    }
    if (prevNode.type === "transition") {
      prevNode = getNode(children[i - 2]);
    }
    let prevEnd = prevNode.conf.end;

    if (currNode.type === "transition") {
      prevEnd = prevEnd - duration / 2;
    }
    if (start !== prevEnd) {
      currNode.conf.start = prevEnd;
      currNode.conf.end = prevEnd + duration;
      magnetMap.set(currNode.id, prevEnd);
    }
  }
}

export function useCreatorStore() {
  return {
    spliting,
    canvasSelected,
    coverPreviewer,
    videoCover,
    defaultCover,
    primaryTrack,
    currentNode,
    widgetMenu,
    segmentMenu,
    animations,
    showTransitionAdder,
    showPopover,
    showCropper,
    dragData,
    replaceData,
    showDiscardCoverDialog,
    showCoverDialog,
    showExportDialog,
    showMaterialDialog,
    clickDialogFrom,
    guideStep,
    dialogCallback,
    magnet,
    magnetMap,
    autoSnap,
    previewAxis,
    timelineEnterd,
    zIndexDelta,
    canPlay,
    resources,
    mediaTab,
    materialTab,
    attrTabMap,
    primaryNode,
    nodeMap,
    nodeSet,
    activeNodeMap,
    videoSpritesheetMap,
    showMaterial,
    voiceoverText,
    updateProject,
    updateCloud,
    refresh,
    empty,
    tracks,
    playing,
    play,
    pause,
    seekTo,
    start,
    destroy,
    getTrack,
    createTrack,
    setActiveNode,
    resize,
    splitNode,
    deleteLeft,
    deleteRight,
    removeNode,
    removeActiveNodes,
    replaceNode,
    getNode,
    addNode,
    getXY,
    timeline,
    copyNode,
    displayFrame,
    totalFrame,
    currentFrame,
    creator,
    frameToWidth,
    widthToFrame,
    secondToFrame,
    addTransitionNode,
    adsorb,
  };
}
