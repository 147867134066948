import { ref, reactive } from "vue";
import { useMessage } from "@/utils";
import type { CardItemInfo } from "../type";
import { getPricingList } from "@/api/premium";

import {
  starterPriorityList,
  standardPriorityList,
  proPriorityList,
  ultraPriorityList,
  starterPlanDescription,
  standardPlanDescription,
  proPlanDescription,
  ultraPlanDescription,
  starterPriorityDescription,
  standardPriorityDescription,
  proPriorityDescription,
  ultraPriorityDescription,
  yearlyStandardPlanDescription,
} from "../constants";
const cardList: CardItemInfo[] = reactive([
  {
    name: "Starter",
    title: "Starter",
    hasDiscount: false,
    yearlyPrice: "$99",
    monthlyDiscount: "",
    monthlyPrice: "",
    packageCode: 0,
    planDescription: starterPlanDescription,
    yearlyPlanDescription: starterPlanDescription,
    buttonText: "Subscribe now",
    priorityDescription: starterPriorityDescription,
    priorityList: starterPriorityList,
    paymentDescription: "Billed annually",
    cycleType: "month",
  },
  {
    name: "Standard",
    title: "Standard",
    hasDiscount: false,
    yearlyPrice: "$99",
    monthlyDiscount: "",
    monthlyPrice: "",
    packageCode: 0,
    planDescription: standardPlanDescription,
    yearlyPlanDescription: standardPlanDescription,
    buttonText: "Subscribe now",
    priorityDescription: standardPriorityDescription,
    priorityList: standardPriorityList,
    paymentDescription: "Billed annually",
    cycleType: "month",
  },
  {
    name: "Pro",
    title: "Pro",
    hasDiscount: false,
    yearlyPrice: "$99",
    monthlyDiscount: "",
    monthlyPrice: "",
    packageCode: 0,
    planDescription: proPlanDescription,
    yearlyPlanDescription: proPlanDescription,
    buttonText: "Subscribe now",
    priorityDescription: proPriorityDescription,
    priorityList: proPriorityList,
    paymentDescription: "Billed annually",
    cycleType: "month",
  },
  {
    name: "Ultra",
    title: "Ultra",
    hasDiscount: false,
    yearlyPrice: "",
    monthlyDiscount: "",
    monthlyPrice: "Let's chat",
    packageCode: 1,
    planDescription: ultraPlanDescription,
    yearlyPlanDescription: ultraPlanDescription,
    buttonText: "Contact us",
    priorityDescription: ultraPriorityDescription,
    priorityList: ultraPriorityList,
    paymentDescription: "Billed annually",
    cycleType: "month",
  },
]);
const monthPricingList: Ref<(ResData.PlanList)[]> =
  ref([]);
const yearPricingList: Ref<(ResData.PlanList)[]> =
  ref([]);
const freeTrial = ref(false);
const currentCycleType = ref("YEAR");

const getPricingCardData = async () => {
  try {
    const { code, data } = await getPricingList();
    if (code === 0) {
      const { freeTrialBoolean, planList } = data;
      freeTrial.value = freeTrialBoolean;
      monthPricingList.value = [];
      yearPricingList.value = [];
      planList.forEach((item) => {
        if (item.subscriptionCycle === "MONTH") {
          monthPricingList.value.push({
            ...item,
          });
        } else if (item.subscriptionCycle === "YEAR") {
          yearPricingList.value.push({
            ...item,
          });
        }
      });
      setCardList("YEAR");
    }
  } catch (error) {
    const message = useMessage();
    message.error("Failed to fetch price list");
  }
};

const setCardList = (cycle: string) => {
  currentCycleType.value = cycle;
  let list = cycle === "YEAR" ? yearPricingList.value : monthPricingList.value;
  list.forEach((item) => {
    cardList.forEach((card, index) => {
      if (card.name === item.subTag) {
        cardList[index] = {
          ...cardList[index],
          couponCode: item.couponCode,
          hasDiscount: item.hasDiscount,
          packageCode: item.packageCode,
          yearlyPrice: item.yearlyPrice,
          cycleType: cycle.toLowerCase(),
          monthlyPrice: item.monthlyPrice,
          monthlyDiscount: item.monthlyDiscountedPrice,
          paymentDescription:
            cycle === "MONTH" ? "Billed monthly" : "Billed annually",
          buttonText: freeTrial.value
            ? "Start 3-day free trial"
            : "Subscribe now",
        };

        // 临时方案
        if (cycle === "YEAR" && card.name === "Pro") {
          Object.assign(cardList[index], {
            couponCode: "YEARSAVE",
            hasDiscount: true,
            monthlyDiscount: "$27.5",
          });
        }
        if (cycle === "MONTH" && card.name === "Pro") {
          Object.assign(cardList[index], {
            couponCode: "PROSAVE",
            hasDiscount: true,
            monthlyDiscount: "$29",
          });
        }
      }
    });
  });

  const ultraIndex = cardList.length - 1;
  cardList[ultraIndex] = {
    ...cardList[ultraIndex],
    paymentDescription:
      cycle === "MONTH" ? "Billed monthly" : "Billed annually",
    cycleType: cycle.toLowerCase(),
  };
};

const getCardInfo = (code: number) => {
  const cardInfo = [...monthPricingList.value, ...yearPricingList.value].find(
    (item) => item.packageCode === code,
  );
  return cardInfo;
};  

export function usePricingStore() {
  return {
    cardList,
    getPricingCardData,
    setCardList,
    getCardInfo,
    currentCycleType,
  };
}
