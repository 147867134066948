<template>
  <teleport to="body">
    <div class="assistance-wrapper common-wrapper" :style="{zIndex}">
      <div class="assistance-container">
        <bv-tip
          content="AI assistant"
          placement="left"
          :disabled="showBox"
          :showAfter="0"
        >
          <svg-icon
            name="icon_assistance"
            class="assistance-trigger"
            :class="{ hide: loading, appear: !loading }"
            @click="clickTrigger"
          />
        </bv-tip>
        <box v-model="showBox" />
      </div>
    </div>
  </teleport>
</template>

<script setup>
import { watch } from 'vue';
import Box from './box.vue';
import { useAssistStore } from '@/store/modules/assist';

const { clearMessages } = useAssistStore();
const props = defineProps({
  loading: {
    type: Boolean,
    default: true,
  },
  zIndex: {
    type: Number,
    default: 2000,
  },
  isShowBox: Boolean,
});

const showBox = ref(false);

const clickTrigger = () => {
  const parentElement = document.getElementsByClassName('script-wrapper')[0];
  if (parentElement) {
    const element = parentElement.getElementsByClassName('box-layout')[0];
    const styles = window.getComputedStyle(element);
    if (styles.opacity == 1) {
      element.classList.remove('appear');
      element.classList.add('hide');
    }
  }
  showBox.value = !showBox.value;
};

const observe = () => {
  let targetElement = null;
  const parentElement = document.getElementsByClassName('common-wrapper')[0];
  if (parentElement) {
    targetElement = parentElement.getElementsByClassName('box-layout')[0];
  }
  const observer = new MutationObserver(function (mutationsList, observer) {
    for (let mutation of mutationsList) {
      if (
        mutation.type === 'attributes' &&
        mutation.attributeName === 'class'
      ) {
        const classNameStr = targetElement.className + '';
        if (classNameStr.includes('appear')) {
          showBox.value = true;
        } else {
          showBox.value = false;
        }
      }
    }
  });

  // 配置观察选项
  const config = { attributes: true, attributeFilter: ['class'] };

  // 开始观察目标元素
  observer.observe(targetElement, config);
};

onBeforeUnmount(clearMessages);

onMounted(() => {
  observe();
  // 弹窗互斥
  watch(showBox, (value) => {
    if (value) {
      const { Gleap } = window;
      if (Gleap) {
        const gleapVisible = Gleap.isOpened();
        gleapVisible && Gleap.close();
      }
    }
  });

  window.Gleap?.on('open', () => {
    if (showBox.value) {
      showBox.value = false;
    }
  });
});
</script>

<style lang="scss" scoped>
.hide {
  transform: scale(0);
  opacity: 0;
  pointer-events: none;
}

.appear {
  opacity: 1;
  pointer-events: all;
}

.assistance-wrapper {
  color: #060606;
  z-index: 2000;
  position: fixed;
  bottom: 88px;
  right: 20px;
}

.assistance-container {
  position: relative;
}

.assistance-trigger {
  width: 48px;
  height: 48px;
  border-radius: 30px;
  box-shadow:
    0px 1px 3px rgba(0, 0, 0, 0.15),
    0px 0px 20px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transform: scale(1);
  transition:
    opacity 0.08s linear 0s,
    box-shadow,
    transform 0.2s ease-in-out;

  &:hover {
    box-shadow:
      0px 1px 3px rgba(0, 0, 0, 0.25),
      0px 0px 20px rgba(0, 0, 0, 0.2);
    transform: scale(1.1);
  }
}
</style>
